import devOps from "../../assets/gif/dev_ops.gif";
import devOpsIconSecurity from "../../assets/icons/dev_ops_icon_security.svg";
import devOpsIconCICD from "../../assets/icons/dev_ops_icon_ci_cd.svg";
import devOpsIconOptimizedManagement from "../../assets/icons/dev_ops_icon_optimized_management.svg";
import devOpsIconPersistentSurveillance from "../../assets/icons/dev_ops_icon_persistent_surveillance.svg";
import devOpsIconAnalyticsInsights from "../../assets/icons/dev_ops_icon_analytics_insights.svg";
import devOpsIconIntegrationMigration from "../../assets/icons/dev_ops_icon_integration_migration.svg";
import devOpsSecurity from "../../assets/gif/dev_ops_security.gif";
import devOpsIntegrationMigration from "../../assets/gif/dev_ops_integration_migration.gif";
import devOpsAnalyticsInsights from "../../assets/gif/dev_ops_analytics_insights.gif";
import devOpsCICD from "../../assets/static/dev_ops_ci_cd.png";
import devOpsPersistentSurveillance from "../../assets/static/dev_ops_persistent_surveillance.png";
import devOpsOptimizedManagement from "../../assets/static/dev_ops_optimized_management.png";

export const data = {
  development: {
    btncolor: "#FFCD38",
    heading: "DEVELOPMENT OPERATIONS",
    imageSrc: devOps,
    buttondirection: "center",
    description:
      "At TACTECH, our prowess in cloud management shines through as we expertly oversee and optimize cloud infrastructures. Beyond managing existing setups, we specialize in elevating them by orchestrating seamless migrations to advanced platforms like AWS and Azure. Embracing the cutting-edge concept of Infrastructure as Code (IaC), we ensure that your cloud environments are not only efficient but also scalable, adapting to your business needs. Our comprehensive approach to cloud management guarantees a smooth and hassle-free experience, allowing you to focus on your core business while we handle the intricacies of cloud technology.",
  },

  services1: {
    heading: "IT'S ALL HERE",
    bgcolor: "#14446d",
    servicesData: [
      {
        imageUrl: devOpsIconSecurity,
        title: "CLOUD SECURITY (DEVSECOPS)",
      },
      {
        imageUrl: devOpsIconCICD,
        title: "CI/CD (CLOUD AUTOMATION)",
      },
      {
        imageUrl: devOpsIconAnalyticsInsights,
        title: "ANALYTICS AND INSIGHTS",
      },
      {
        imageUrl: devOpsIconOptimizedManagement,
        title: "OPTIMIZED MANAGEMENT",
      },
      {
        imageUrl: devOpsIconIntegrationMigration,
        title: "INTEGRATIONS AND MIGRATIONS",
      },
      {
        imageUrl: devOpsIconPersistentSurveillance,
        title: "PERSISTENT SURVEILLANCE",
      },
    ],
  },

  incubationfunding: {
    order: "md:order-2",
    title: "CLOUD SECURITY (DEVSECOPS)",
    imageSrc: devOpsSecurity,
    description:
      "Security stands paramount for organizations transitioning to the cloud, seeking unwavering assurance in the safety of their platforms and data. TACTECH not only addresses this concern but excels in crafting, deploying, and maintaining secure cloud platforms through cutting-edge DevOps methodologies. With us, you're not just embracing the cloud; you're embracing a fortified future.",
  },
  incubationfunding1: {
    // order: "md:order-2",
    title: "CI/CD (CLOUD AUTOMATION)",
    bgcolor: "#F5F5F5",
    imageSrc: devOpsCICD,
    description:
      "Crafting a strategic blueprint for the application of DevOps tools, practices, and methodologies is pivotal in expediting technology delivery at scale within the cloud ecosystem. The integration of Continuous Integration and Continuous Deployment (CI/CD) serves as a cornerstone, establishing a seamless and automated framework for constructing, packaging, and rigorously testing applications.",
  },
  incubationfunding2: {
    order: "md:order-2",
    title: "ANALYTICS AND INSIGHTS",
    imageSrc: devOpsAnalyticsInsights,
    description:
      "In the realm of DevOps, we excel in the art of collecting and presenting pivotal insights and analytics. By harnessing comprehensive data collection and advanced analytical techniques, we equip businesses with the tools to decode patterns, unearth opportunities, and refine strategies. Our unwavering dedication to delivering actionable intelligence ensures that in the dynamic world of DevOps, you make informed decisions, enhance operational agility, and propel your growth journey.",
  },
  incubationfunding3: {
    // order: "md:order-2",
    title: "OPTIMIZED MANAGEMENT",
    imageSrc: devOpsOptimizedManagement,
    description:
      "We methodically evaluate our clients' environment, workloads, and applications to meticulously plan their cloud migration approach. We seamlessly transition from planning to practicality, focusing on flawless execution. Beyond appearances, our success hinges on migrating and maintaining production applications with zero downtime, where data and applications are deftly migrated, neutralized, re-platformed, and fortified for resilience, all while leveraging load balancers for optimal performance.",
  },
  incubationfunding4: {
    order: "md:order-2",
    title: "INTEGRATIONS AND MIGRATIONS",
    imageSrc: devOpsIntegrationMigration,
    description:
      "An underkill leads to failure, an overkill becomes detrimental. At the heart of our approach is a meticulous evaluation of your current cloud infrastructure. We delve deep into its architecture, performance, and alignment with your goals. If opportunities for enhancement arise, we expertly fine-tune and optimize your existing setup. In cases where a transition to a more suitable service provider is warranted, we orchestrate seamless migrations. Our aim is to empower you with a robust and tailored cloud ecosystem that not only meets your current needs but also propels your future endeavors with unwavering efficiency and innovation.",
  },
  incubationfunding5: {
    // order: "md:order-2",
    bgcolor: "#F5F5F5",
    title: "PERSISTENT SURVEILLANCE",
    imageSrc: devOpsPersistentSurveillance,
    description:
      "At TACTECH, we exercise vigilant oversight of your cloud infrastructure to ensure unwavering health and optimal performance. Through persistent surveillance, we proactively detect, analyze, and address any potential issues, safeguarding your digital ecosystem from disruptions. Our commitment to continuous monitoring guarantees a seamless experience, allowing you to focus on your core objectives while we uphold the stability and efficiency of your cloud environment.",
  },
};
