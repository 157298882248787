import hero from "../assets/gif/hero.gif";
import scrollDown from "../assets/gif/scroll_down.gif";

interface HeroProps {
  image?: string;
  content?: {
    heading: string;
    paragraph?: string;
  };
}

const Hero: React.FC<HeroProps> = ({ image, content }) => {
  // const handleScrollClick = () => {
  //
  //   // Calculate the height of one screen
  //   const screenHeight = window.innerHeight;

  //   // Scroll down by one screen height
  //   window.scrollTo({
  //     top: window.scrollY + screenHeight,
  //     behavior: 'smooth'
  //   });
  // };

  // useEffect(() => {
  //   handleScrollClick();
  // }, [])

  return (
    <div>
      <div>
        <div className="h-screen relative">
          {/* <div className="absolute z-20 left-1/4 top-[18%] xs:top-[30%] md:top-[35%] right-0 bottom-5  w-1/2 sm:w-2/4 md:w-1/3 lg:w-2/4 xl:w-2/4 leading-6 text-center">
            <h3 className="text-white font-semibold text-center md:text-5xl text-xl flex justify-center">
              {content.heading}
            </h3>
            {content.paragraph && (
              <h1 className="text-[#FEA315] font-bold sm:text-base text-xs md:mt-7 mt-3 font-normal">
                {content.paragraph}
              </h1>
            )}
            <div className="flex flex-wrap  md:gap-10 gap-3 justify-center mt-10">
              <button className="bg-[#198754] text-white  border-[1px] border-[#198754] md:px-10 px-3 py-2 rounded-full hover:bg-transparent hover:text-[#198754]  transition-colors duration-700 transition-delay-500 ease-in-out">
                View Services
              </button>
              <button className="bg-transparent text-[#198754]  border-[1px] border-[#198754] md:px-10 px-3 py-2 rounded-full hover:bg-[#198754] hover:text-white  transition-colors duration-700 transition-delay-500 ease-in-out">
                View Portfolio
              </button>
            </div>
          </div> */}

          <div className="w-full h-full filter relative -z-50 bg-black">
            <img src={hero} className="object-contain w-full h-full" alt="" />
            <div className="relative flex justify-center">
              <img
                src={scrollDown}
                alt="Scroll Down"
                className="absolute bottom-10 right-10 h-[5vw] min-h-[70px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
