import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { getRandomAnimation } from "../../utils";
import Button from "../Button";

interface Service {
  logo: string;
  title: string;
  description: string;
}

interface ServicesProps {
  data: {
    services: Service[];
    title: string;
    subtitle: string;
    imageUrl: string;
  };
  formRef?: React.RefObject<HTMLDivElement | null>;
}

const Services: React.FC<ServicesProps> = ({ data, formRef }) => {
  //
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);
  //
  const scrollToForm = () => {
    console.log(formRef);
    // if (formRef && formRef.current) {
    //   formRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  };
  //
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !hasScrolledToEnd &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 1500
      ) {
        AOS.refresh();
        setHasScrolledToEnd(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledToEnd]);
  const { services, title, subtitle, imageUrl } = data;

  return (
    <>
      <div className="grid sm:px-20 grid-cols-1 sm:grid-cols-3 gap-4 overflow-x-hidden">
        <div className="fussion_fullwidth sm:order-2"></div>
        <div
          className="justify-center hidden"
          data-aos={hasScrolledToEnd ? "" : `fade-${getRandomAnimation()}`}
          data-aos-offset="10"
          data-aos-easing="ease-in-sine"
        >
          <img
            src={imageUrl}
            className="h-full w-[130px] object-cover mt-10"
            alt=""
          />
        </div>
        <div
          className="px-20 py-14 sm:col-span-2  sm:order-1"
          data-aos={hasScrolledToEnd ? "" : `fade-${getRandomAnimation()}`}
          data-aos-offset="10"
          data-aos-easing="ease-in-sine"
        >
          <div className="flex flex-col sm:justify-center">
            <h1 className="sm:text-[40px] text-2xl font-semibold text-center">
              {title}
            </h1>
            <p className="sm:text-[26px] text-xl mt-5 text-center">
              {subtitle}
            </p>
            <div className="grid sm:grid-cols-2 mt-20 grid-cols-1 gap-4">
              {services.map((service, index) => (
                <div key={index} className="flex flex-col">
                  <div className="flex justify-center">
                    <img
                      className="sm:w-[70px] w-[50px]"
                      src={service.logo}
                      alt=""
                    />
                  </div>
                  <h1 className="sm:text-[25px] text-center text-xl font-semibold mt-4">
                    {service.title}
                  </h1>
                  <hr className="my-5" />
                  <p>{service.description}</p>
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-20">
              <Button
                text="Get Started"
                color="#FEA315"
                bgColor="transparent"
                borderColor="#FEA315"
                onClick={scrollToForm}
                hoverBgColor="#FEA315"
                hoverColor="#FFFFFF"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
