export const data = {
  aboutussinfo: [
    {
      heading: "OUR JOURNEY",
      paragraph: [
        "TACTECH stands at the forefront of innovation in the tech industry, emerging as a dynamic force in the realm of software solutions. With a visionary approach and a commitment to excellence, TACTECH leverages cutting-edge technologies to craft bespoke software solutions tailored to meet the unique needs of businesses across various sectors. Our dedicated team of experts brings a wealth of knowledge and expertise to every project, ensuring seamless integration and transformative results.",
        "TACTECH's passion for pushing boundaries and delivering top-tier products has earned us a reputation for reliability and innovation in the ever-evolving landscape of technology solutions. As a rising star in the industry, TACTECH is poised to shape the future of software development, setting new standards for quality and ingenuity."
      ],
    },
    {
      heading: "WHAT YOU MEAN TO US",
      paragraph: [
        "At TACTECH, our clients are at the heart of everything we do. You're not just a project; you're a valued partner on a shared journey toward success. Your goals, challenges, and vision drive our passion for innovation and excellence. We're committed to not only meeting your expectations but exceeding them, every step of the way. Your success is our success, and we're honored to be a part of your story.",
      ],
    },
    {
      heading: "OUR MANTRA",
      paragraph: [
        "Our guiding mantra is 'Innovate, Transform, Excel'. This powerful ethos encapsulates our unwavering commitment to driving progress and delivering excellence in every facet of our work.",
        "Innovation is the cornerstone of our approach. We are driven by a relentless pursuit of cutting-edge solutions, leveraging the latest technologies and creative thinking to solve complex problems. This forward-thinking mindset allows us to anticipate industry trends, adapt swiftly to change, and stay at the forefront of technological advancements.",
        "Transformation is not merely a goal, but a fundamental principle that governs our actions. We believe in the power of change to unlock new possibilities and drive growth. Whether it's revolutionizing business processes or reinventing user experiences, we are dedicated to catalyzing positive transformation that propels our clients and partners forward.",
        "Excellence is our standard, the benchmark against which we measure our achievements. It is not just about meeting expectations, but surpassing them. Our team of seasoned experts is driven by an unyielding commitment to quality, precision, and attention to detail. This unwavering pursuit of excellence is what sets us apart and fuels our success.",
        "This mantra guides us in every project, every decision, and every interaction. It is a reflection of our values, our culture, and our unwavering dedication to empowering our clients to succeed in the dynamic world of technology and business."

      ],
    },
    {
      heading: "WHAT'S UNIQUE HERE?",
      paragraph: [
        "At TACTECH, our distinct advantage lies in our unwavering commitment to complete transparency and client-centricity. We believe in keeping you fully informed and involved throughout the project journey, providing you with a sense of ease and assurance. This means regular updates, open communication, and a collaborative approach that ensures every decision aligns with your vision.",
        "What truly sets us apart is our deep investment in your business. We don't just view ourselves as service providers; we consider ourselves an extension of your team, dedicated to understanding your goals and driving your success. This level of commitment translates into a seamless partnership, where your objectives become our own.",
        "By combining these principles with our technical expertise and innovative solutions, we not only deliver outstanding results but also foster a relationship built on trust and shared achievement. This is the essence of our Unique Selling Point and the foundation on which we build enduring partnerships with you."
      ],
    },
    // {
    //   heading: "INVESTING IN STARTUPS",
    //   paragraph: [
    //     "The startup story for us started after the success of EatOye.pk. Pakistan’s largest online food ordering portal, EatOye.pk was acquired by TACTECH in 2013 and later sold to Rocket Internet, the world leader in ecommerce businesses. It was this inspiration that led to the inception of TACTECH Technology Ventures (ATV) in 2015. Till to date, ATV has incubated upon leading local ventures and provided startup support to established local names, such as, Sheops and Forrun. Pakistan’s biggest venture capitalist, ATV today owns diversified businesses that range from online shopping to video curation.",
    //   ],
    // },
    // {
    //   heading: "CELEBRATING 17 YEARS OF SUCCESS!",
    //   paragraph: [
    //     "Taking birth in a competitive environment, one that demanded on-the-toe approach, TACTECH has come a long way since its inception 17 years ago. The journey has helped us specialize in ecommerce platform and mobile application development. It’s time we pulled on our socks and paused on our successes. Today, our rebranded corporate identity speaks about our newfound focus. Exploring the challenging ecommerce markets of the US and the UK, we are all set to rule the world. Up for the challenge ahead, we look forward to creating the same success as we have displayed in the last 17 years.",
    //     "With a customer-centric approach, we are ready to serve your ecommerce Web Store, Mobile Application Development, Website Development and Managed Cloud Hosting needs, while further supporting your business with Digital Outreach, Business Metrics and Dashboards.",
    //     "Another milestone in our 17-year journey is the achievement of four major ISO certifications that shows TACTECH unparalleled commitment to seamless information technology and support services for its clients and relentless focus on customer satisfaction.",
    //     "The certifications include ISO 22301:2019, ISO 9001:2015, ISO 27001:2013, and ISO 20000-1:2018.",
    //   ],
    // },
  ],
};
