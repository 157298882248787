import laravelSecureBackups from "../../assets/gif/laravel_secure_backups.gif";
import laravelAirtightFortification from "../../assets/gif/laravel_airtight_fortification.gif";
import laravelUnwaveringAssistance from "../../assets/gif/laravel_unwavering_assistance.gif";
import laravel from "../../assets/gif/laravel.gif";
import startUpWebDevelopment from "../../assets/gif/startup_web_development.gif";
import laravelApiDevelopment from "../../assets/gif/laravel_api_development.gif";
import laravelCodeAudit from "../../assets/gif/laravel_code_audit.gif";
import laravelWhy from "../../assets/static/laravel_why.png";

export const data = {
  development: {
    btncolor: "#D1544A",
    heading: "LARAVEL - A NEW DIMENSION OF PHP",
    imageSrc: laravel,
    buttondirection: "center",
    description:
      "Laravel stands as a PHP web framework known for its sophisticated and well-structured architecture. Central to its design is the Model-View-Controller (MVC) paradigm, a strategic separation that enhances code organization and scalability. Beyond this, Laravel's modular approach simplifies development through an array of pre-built tools and libraries, facilitating the rapid and secure construction of web applications. With its emphasis on elegant coding practices and intuitive syntax, Laravel empowers developers to create resilient solutions swiftly, cementing its role as an essential player in contemporary web development.",
  },
  services: {
    heading: "WHAT DO YOU SEEK?",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: laravelApiDevelopment,
        title: "API DEVELOPMENT",
        description:
          "Discover seamless API development for Laravel. Our expertise lies in crafting robust interfaces that bridge your applications and systems. Through leveraging Laravel's prowess, we engineer APIs that guarantee secure, efficient data exchange, fostering enhanced interoperability.",
      },
      {
        imageUrl: startUpWebDevelopment,
        title: "WEB DESIGN & DEVELOPMENT",
        link: "/web-design-development",
        description:
          "TACTECH harnesses the power of Laravel to craft cutting-edge websites that seamlessly blend innovation with functionality. Leveraging Laravel's advanced features and elegant syntax, we ensure our creations stand as benchmarks of modern web design and development.",
      },
      {
        imageUrl: laravelCodeAudit,
        title: "CODE AUDIT AND OPTIMIZATION",
        description:
          "We conduct thorough code audits and optimization for existing Laravel projects, meticulously examining every line to uncover inefficiencies and bottlenecks. Through strategic enhancements, we fine-tune your codebase, ensuring your Laravel projects operate at their peak potential.",
      },
    ],
  },
  laravel: {
    heading: "WHY LARAVEL?",
    imageSrc: laravelWhy,
    description: [
      "Laravel embodies a powerhouse of capabilities, seamlessly merging elegant syntax with advanced functionalities. Its dynamic ecosystem empowers developers to create sophisticated web applications with speed, precision, and scalability.",
      "Eloquent ORM: Laravel's Eloquent ORM provides an intuitive way to work with databases, simplifying data manipulation and relationships within your application.",
      "Artisan Command Line: The Artisan command-line tool automates repetitive tasks, offering functionalities like generating code, managing migrations, and more.",
      "MVC Architecture: Laravel follows the Model-View-Controller pattern, aiding in the separation of concerns and enhancing code organization for maintainability.",
      "Routing and Middleware: Laravel's robust routing system and middleware enable precise control over incoming requests, making authentication, caching, and other tasks more streamlined.",
      "Authentication and Authorization: Laravel offers built-in authentication scaffolding and fine-grained authorization mechanisms, securing your application with ease.",
    ],
  },
  developmentservices: {
    heading: "HOW WE HELP",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: laravelSecureBackups,
        title: "SECURE BACKUPS",
        description:
          "Safeguard your Laravel application's files and databases effortlessly using the native cloud file system provided by Laravel. Rest assured, your data is fully secured, and in case of any backup issues, you'll receive timely notifications.",
      },
      {
        imageUrl: laravelAirtightFortification,
        title: "AIRTIGHT FORTIFICATION",
        description:
          "We implement a multi-layered approach that includes robust authentication, authorization mechanisms, and encryption protocols. Regular security audits and updates fortify your application against vulnerabilities, ensuring your data remains protected.",
      },
      {
        imageUrl: laravelUnwaveringAssistance,
        title: "UNWAVERING ASSISTANCE",
        description:
          "Benefit from the expertise of our seasoned Laravel professionals, ensuring tailored solutions and continuous support as we accompany you through every phase of your Laravel journey, making your success our goal and your achievements ours.",
      },
    ],
  },
};
