export const projects = [
  {
    imgurl:
      "https://propertycloud.ai/static/media/logo.5f1d108c8bd458b2dbbeb6c948e8cfd5.svg",
    title: "Property Cloud",
    description: "AI Powered Prop-Tech Solution",
    longDescription:
      "Effectively conceptualizing, engineering, and sustaining an AI-powered real estate management CRM, available on a subscription basis, which can be used by real estate agencies to manage all of their agency needs. They can manage their leads, properties, projects, and conversions, while also managing the internal infrastructure of the agency with its state-of-the-art HR module. Reports and analytics are available at every level. The dashboard itself is fully customizable, with a custom color selection with the availability of both dark and light modes.",
    bgColor: "#f6f6f6",
    stacks: ["React TypeScript", "Node TypeScript", "Express", "MongoDB"],
    extraTechnology: [
      "Facebook Graph API",
      "Instagram Graph API",
      "MailerSend",
      "Stripe",
    ],
    available: true,
    viewAt: "https://www.propertycloud.ai",
  },
  {
    imgurl:
      "https://www.dominatewear.com/cdn/shop/files/Logo_1.jpg?v=1698794377&width=500",
    title: "Dominate Wear",
    description: "Luxury Active and Street Wear",
    longDescription:
      "This is a clothing store, for a well-known athleisure brand called Dominate Wear. The user experience has been streamlined, with minimized third-party apps, to maximize the speed score and responsiveness of the website. A continuous process of ongoing third-party integrations is successfully taking place, with services such as Slack and Klaviyo to streamline sales and marketing procedures.",
    bgColor: "#25282c",
    stacks: ["Shopify"],
    extraTechnology: ["Google Analytics", "Google Ad Mob", "2checkout"],
    available: true,
    viewAt: "https://www.dominatewear.com",
  },
  {
    imgurl:
      "https://www.xcessive.xyz/static/media/logo.0e55c007233943e8647e.png",
    title: "XCESSIVE",
    description: "A blockchain-based art experience",
    longDescription:
      "Conceptualized and developed this cryptocurrency token website, where users can access information about the Xcessive Token, which has been newly launched to the Cryptocurrency exchanges.",
    bgColor: "#f6f6f6",
    stacks: ["React TypeScript", "Node TypeScript", "Express", "MongoDB"],
    extraTechnology: [],
    available: true,
    viewAt: "https://www.xcessive.xyz",
  },
  {
    imgurl:
      "https://images.vexels.com/media/users/3/200924/isolated/preview/d43811ce3e38102e80764d109e4349db-delivery-app-notification-icon.png",
    title: "Cafe Order",
    description: "Food ordering - Reimagined",
    longDescription:
      "Successfully designed and developed this food ordering app for an international university (name not disclosed), which users can use to order food from the university cafeteria, and place their orders in advance, instead of waiting in long queues. The user experience is double-faceted, with the users also being able to log in as food vendors, so they can publish their menus, which other users can order. The app is customizable with the availability of both dark and light modes.",
    bgColor: "#f6f6f6",
    stacks: ["Google Firebase", "Flutter"],
    extraTechnology: ["Stripe"],
    available: false,
    viewAt: "",
  },
  {
    imgurl:
      "https://selflovebycheenoz.com/cdn/shop/files/logo_selflove_website_f21aa046-e459-4fe1-8e56-ff9728e947b7_120x@2x.png?v=1634232667",
    title: "Self Love by Cheeno'z",
    description: "100% Organic, High Efficacy Skin Care",
    longDescription:
      "This is a skincare and haircare Shopify Store. The user experience has been streamlined, with minimized third party apps, to maximize the speed score and responsiveness of the website. Furthermore, a lot of design improvements have been made to ensure that a free Shopify theme could resemble a paid theme. Everything was done through the addition of HTML, CSS and Javascript. The sitemap of the website was also optimized for excellent SEO.",
    bgColor: "#f6f6f6",
    stacks: ["Shopify"],
    extraTechnology: ["Google Analytics", "Google Ad Mob", "2checkout"],
    available: true,
    viewAt: "https://selflovebycheenoz.com/",
  },
];
