import Breadcrumb from "../Breadcrumb";
import React, { useRef } from "react";
import Footer from "../Footer";
import Forms from "../Forms";
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";
import DevelopmentServices from "./DevelopmentServices";
import { data } from "./constants";
import Hero from "../Hero";

const WebDevelopmentComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="overflow-hidden">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"Web Design & Development"} />
      <DevelopmentServices data={data.brandsdata} formRef={formRef} />
      <DevelopmentServices data={data.ecommercedata} />
      <DevelopmentServices data={data.developmentservices} />
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default WebDevelopmentComponent;
