import BusinessIntelligenceComponent from "../component/BusinessIntelligence";

const BusinessIntelligence = () => {
  return (
    <div>
      <BusinessIntelligenceComponent />
    </div>
  );
};

export default BusinessIntelligence;
