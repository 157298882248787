import React, { useState } from "react";

interface ButtonProps {
  text: string;
  color: string;
  bgColor: string;
  borderColor: string;
  hoverBgColor: string;
  hoverColor: "#14446d" | "#FFFFFF" | "#D35D54";
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  text,
  color,
  bgColor,
  borderColor,
  hoverBgColor,
  hoverColor,
  onClick,
}) => {
  const buttonStyle = {
    color: color,
    backgroundColor: bgColor,
    borderColor: borderColor,
  };

  const hoverStyle = {
    backgroundColor: hoverBgColor,
    color: hoverColor,
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      onClick={onClick}
      className="border-[1px] font-semibold sm:px-10 px-5 py-2 rounded-full transition-colors duration-700 transition-delay-500 ease-in-out"
      style={{ ...buttonStyle, ...(isHovered ? hoverStyle : {}) }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </button>
  );
};

export default Button;
