import React, { useState, useEffect } from "react";
import logo from "../../assets/static/logo.png";
import { AiOutlineClose } from "react-icons/ai";
import { BiMenuAltRight } from "react-icons/bi";
// import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Quotes from "../Quotes";
import ServicesModal from "./ServicesModal";
// import webDevelopment from "../../assets/icons/services_icon_web_development.svg";
// import mobileDevelopment from "../../assets/icons/services_icon_mobile_development.svg";
// import softwareDevelopment from "../../assets/icons/services_icon_software_development.svg";
// import startUps from "../../assets/icons/services_icon_start_ups.svg";
// import devOps from "../../assets/icons/services_icon_dev_ops.svg";
// import itResourceManagement from "../../assets/icons/services_icon_it_resource_management.svg";
// import laravelDevelopment from "../../assets/icons/services_icon_laravel.svg";
// import eCommerceDevelopment from "../../assets/icons/services_icon_ecommerce.svg";
// import cloudManagementHosting from "../../assets/icons/services_icon_cloud_management_hosting.svg";
// import dataAnalytics from "../../assets/icons/services_icon_data_analytics.svg";
// import digitalMarketing from "../../assets/icons/services_icon_digital_marketing.svg";

interface NavbarProps {
  color?: string;
  formRef?: React.RefObject<HTMLDivElement | null>;
}

const Navbar: React.FC<NavbarProps> = ({ color, formRef }) => {
  const [backgroundColor, setBackgroundColor] = useState(
    color || "transparent"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const handleScroll = (): void => {
    const currentPosition = window.scrollY;

    if (currentPosition > 100) {
      setBackgroundColor("#3e3e3e");
    } else {
      setBackgroundColor(color || "transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen]);

  return (
    <div
      style={{
        background: !isMenuOpen ? backgroundColor : "#3e3e3e",
        minWidth: "100vw",
        minHeight: "2vh",
      }}
      className={`${"fixed"} w-screen top-0 z-50  xs:px-2 lg:px-6 bg-transparent    backdrop-blur  transition-opacity duration-300`}
    >
      <header className="sticky top-0 z-50 px-4 lg:px-6 w-full backdrop-blur">
        <nav className="sticky top-0 z-50 p-3  px-4 lg:px-6 bg-transparent w-full">
          <div className="max-w-screen-xl mx-auto flex justify-between items-center ">
            <div className="flex items-center">
              <img
                src={logo}
                className="object-cover cursor-pointer"
                onClick={() => navigate("/")}
                width={80}
                alt="reload"
              />
              <p className="text-transparent tracking-widest uppercase ml-2">
                TACTECH
              </p>
            </div>
            <div className="hidden lg:flex items-center justify-center space-x-8">
              <ul className="flex gap-10 justify-center font-medium">
                <li>
                  <a
                    href="/"
                    className="block py-2 pr-4 pl-3 text-white  lg:bg-transparent lg:text-primary-700 lg:p-0 "
                  >
                    Home
                  </a>
                </li>
                <li>
                  <ServicesModal formRef={formRef} />
                </li>
                <li>
                  <a
                    href="/projects"
                    className="block py-2 pr-4 pl-3 text-white lg:bg-transparent lg:text-primary-700 lg:p-0 "
                  >
                    Projects
                  </a>
                </li>
                <li>
                  <a
                    href="/about-us"
                    className="block py-2 pr-4 pl-3 text-white lg:bg-transparent lg:text-primary-700 lg:p-0 "
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/contact-us"
                    className="block py-2 pr-4 pl-3 text-white  lg:bg-transparent lg:text-primary-700 lg:p-0 "
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="hidden lg:flex items-center justify-center space-x-8">
              {/* <button className="bg-[#198754] text-white  border-[1px] border-[#198754] px-10 py-2 rounded-full hover:bg-transparent hover:text-[#198754]  transition-colors duration-700 transition-delay-500 ease-in-out"> */}
              <Quotes />
              {/* Get Started */}
              {/* </button> */}
            </div>
            <div className="lg:hidden flex items-center">
              <button
                className="text-white focus:outline-none"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? (
                  <AiOutlineClose size={"1.5rem"} />
                ) : (
                  <BiMenuAltRight size={"2rem"} />
                )}
              </button>
            </div>
          </div>
          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="lg:hidden mt-4">
              <ul className="flex flex-col space-y-4 font-medium">
                <li className="text-left">
                  <a
                    href="/"
                    className="block py-2 pr-4 pl-3  text-white hover:text-black border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 "
                    aria-current="page"
                  >
                    Home
                  </a>
                </li>

                {/* Service Sub Menu */}
                <ServicesModal
                  formRef={formRef}
                  onDontKnow={() => setIsMenuOpen(false)}
                />
                <li className="text-left w-full">
                  <a
                    href="/projects"
                    className="block py-2 pr-4 pl-3 text-white border-b hover:text-black border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 "
                  >
                    Projects
                  </a>
                </li>
                <li className="text-left w-full">
                  <a
                    href="/about-us"
                    className="block py-2 pr-4 pl-3 text-white border-b hover:text-black border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 "
                  >
                    About
                  </a>
                </li>
                <li className="text-left w-full">
                  <a
                    href="/contact-us"
                    className="block py-2 pr-4 pl-3 text-white border-b hover:text-black border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 "
                  >
                    Contact
                  </a>
                </li>
                <div className="py-10">
                  <Quotes />
                </div>
              </ul>
            </div>
          )}
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
