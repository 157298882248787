import React, { useRef } from "react";
import Card from "./Card";
import ScrollToTop from "../ScrollToTop";
import Hero from "../Hero";
import Header from "../Header";
import Breadcrumb from "../Breadcrumb";
import Forms from "../Forms";
import Footer from "../Footer";

const ProjectComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="overflow-hidden">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"Projects"} />
      <Card />
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default ProjectComponent;
