import aws from "../../assets/static/aws.png";
import azure from "../../assets/static/azure.png";
import digitalOcean from "../../assets/static/digital_ocean.png";
import vultr from "../../assets/static/vultr.png";
import apache from "../../assets/static/apache.png";
import redis from "../../assets/static/redis.png";
import memcached from "../../assets/static/memcached.png";
import cloudManagementHosting from "../../assets/gif/cloud_management_hosting.gif";
import continuousSurveillance from "../../assets/gif/cloud_management_continuous_surveillance.gif";
import serverOptimizations from "../../assets/gif/cloud_management_server_optimization.gif";
import secureBackups from "../../assets/gif/cloud_management_secure_backups.gif";
import selfHealing from "../../assets/gif/cloud_management_self_healing.gif";
import minimumDowntime from "../../assets/gif/cloud_management_minimum_downtime.gif";

export const data = {
  development: {
    btncolor: "#591661",
    heading: "CLOUD MANAGEMENT & HOSTING",
    imageSrc: cloudManagementHosting,
    buttondirection: "center",
    description:
      "Unlock the potential of customized solutions with our Cloud Management Services. We provide secure, flexible, and highly available environments, offering a range of options including public, private, dedicated servers, colocation, or hybrids. With skilled administrators and comprehensive support, our managed cloud hosting ensures seamless server management, allowing you to prioritize your business objectives.",
  },

  services: {
    heading: "OUR EXPERIENCE WITH TECHNOLOGY",
    bgcolor: "#F5F5F5",
    title:
      "TACTECH harnesses leading-edge technologies and tactics to deliver breakthrough solutions. Our experts are adept at utilizing various software and technologies.",
    servicesData: [
      {
        imageUrl: aws,
        description:
          "The industry leader in cloud services, offering unmatched scalability, security, and a wide array of services for robust cloud hosting.",
      },
      {
        imageUrl: azure,
        description:
          "Enterprise-grade solutions, seamless integration, and a range of services on Microsoft's comprehensive cloud platform.",
      },
      {
        imageUrl: digitalOcean,
        description:
          "User-friendly cloud provider, favored for simplicity and developer-friendly environment, making cloud hosting accessible for startups.",
      },
      {
        imageUrl: vultr,
        description:
          "High-performance cloud computing with straightforward pricing, solid infrastructure, and global presence, popular among developers.",
      },
      {
        imageUrl: apache,
        description:
          "Foundational web server software, powering a significant portion of websites worldwide, crucial in cloud hosting and management.",
      },
      {
        imageUrl: redis,
        description:
          "In-memory data structure store, crucial for high-performance and low-latency applications, a key component in cloud environments.",
      },
      {
        imageUrl: memcached,
        description:
          "Widely used caching system enhancing database performance, crucial for efficient cloud hosting.",
      },
    ],
  },
  benefits: {
    heading: "HOW WE HELP",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: continuousSurveillance,
        title: "CONTINUOUS SURVEILLANCE",
        description:
          "Our vigilant round-the-clock server and application monitoring ensures timely alerts in cases of heightened traffic and server resource consumption, maintaining seamless operation. ",
      },
      {
        imageUrl: serverOptimizations,
        title: "SERVER OPTIMIZATIONS",
        description:
          "We excel in server optimizations, conducting meticulous assessments and implementing strategic adjustments to configurations, resources, and software. This ensures that your servers operate at peak efficiency, delivering a seamless and enhanced user experience.",
      },
      {
        imageUrl: secureBackups,
        title: "SECURE BACKUPS",
        description:
          "We employ a comprehensive data backup strategy, regularly scheduled to safeguard against any potential loss. Our automated backups are stored in secure, redundant locations, ensuring that your critical information remains intact and readily recoverable at all times.",
      },
      {
        imageUrl: selfHealing,
        title: "SELF-HEALING",
        description:
          "We implement advanced self-healing mechanisms on our servers, constantly monitoring for potential issues. In the event of a disruption, these mechanisms swiftly take corrective action, ensuring uninterrupted and smooth operations for your applications and services.",
      },
      {
        imageUrl: minimumDowntime,
        title: "MINIMUM DOWNTIME",
        description:
          "We employ a combination of redundant systems, proactive maintenance, and strategic load balancing to minimize server downtime. Additionally, our vigilant monitoring and prompt issue resolution protocols guarantee that any potential disruptions are swiftly addressed, ensuring uninterrupted operations for your applications.",
      },
    ],
  },
};
