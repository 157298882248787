import React, { useState } from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRightShort } from "react-icons/bs";
import { BiX } from "react-icons/bi";

interface Project {
  imgurl: string;
  title: string;
  description: string;
  longDescription: string;
  bgColor: string;
  stacks: string[];
  extraTechnology: string[];
  available: boolean;
  viewAt: string;
}

interface ProjectModalProps {
  project: Project;
}

const customStyles: Modal.Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000, // Higher zIndex to cover everything
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "90%",
    maxWidth: "800px",
    height: "75%",
    transform: "translate(-50%, -50%)",
    backdropFilter: "blur(5px) !important",
    animation: "slideInFromTop 0.5s ease-out 0.2s forwards",
    background: "rgba(255, 255, 255, 1)",
    border: "none",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    overflow: "scroll",
  },
};

const ProjectModal: React.FC<ProjectModalProps> = (props) => {
  let subtitle: HTMLHeadingElement | null;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    if (subtitle) {
      subtitle.style.color = "#2E96C7";
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="App">
      <button
        onClick={openModal}
        data-bs-toggle="modal"
        data-bs-target="#quoteModal"
        className="text-left flex mt-2 my-auto"
      >
        <p style={{ fontSize: "12px" }}>View Detail </p>
        <BsArrowRightShort
          size={"2rem"}
          className="-mt-2 hover:translate-x-2 duration-100 "
        />
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Project Details Modal"
        ariaHideApp={false}
      >
        <div className="flex justify-end absolute right-5">
          <button className=" focus:outline-none" onClick={() => closeModal()}>
            <AiOutlineClose
              size={"2rem"}
              color="#FEA315"
              style={{ marginRight: "1rem", marginTop: "1rem" }}
            />
          </button>
        </div>
        <div className="flex flex-col App">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              backgroundColor: props.project.bgColor,
            }}
          >
            <img
              className="w-full my-auto object-contain max-h-[40vh] mt-5"
              src={props.project.imgurl}
              alt={`reload`}
            />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <h1 className="text-center text-lg sm:text-[30px] font-semibold py-5">
                {props.project.title}
              </h1>
              {props.project.available && (
                <button
                  className="text-left flex mt-2 my-auto flex"
                  style={{ alignItems: "start", paddingTop: "1rem" }}
                  onClick={() => {
                    window.open(props.project.viewAt, "_blank");
                  }}
                >
                  <p style={{ fontSize: "14px" }}>Check it Out</p>
                  <BsArrowRightShort
                    size={"2.2rem"}
                    color={"#0D709E"}
                    className="-mt-2 hover:translate-x-2 duration-100 "
                  />
                </button>
              )}
              {!props.project.available && (
                <button
                  className="text-center flex mt-2 my-auto flex"
                  style={{
                    alignItems: "start",
                    paddingTop: "1rem",
                    cursor: "not-allowed",
                  }}
                >
                  <p style={{ fontSize: "14px" }}>
                    (Only Available Internally)
                  </p>
                </button>
              )}
            </div>

            <h1
              className="font-semibold mb-3 break-all text-center"
              style={{ fontSize: "18px" }}
            >
              {props.project.description}
            </h1>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <div
              style={{
                height: "1px",
                width: "50%",
                backgroundColor: "gray",
                opacity: "0.5",
              }}
            ></div>
          </div>

          <div className="grid grid-cols-2 gap-10 sm:gap-10 md:gap-16 my-5">
            <div
              className="col-span-2 sm:col-span-1"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                Technology Utilized
              </p>
              {props.project.stacks.map((stack) => {
                return <p style={{ fontSize: "16px" }}>&#8226; {stack}</p>;
              })}
            </div>
            <div
              className="col-span-2 sm:col-span-1"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                Extra Integrations
              </p>
              {props.project.extraTechnology.map((stack) => {
                return <p style={{ fontSize: "16px" }}>&#8226; {stack}</p>;
              })}
            </div>
          </div>
          <small style={{paddingTop: '1rem', fontSize: '16px', textAlign: 'center'}} >{props.project.longDescription}</small>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectModal;
