import Breadcrumb from "../Breadcrumb";
import React, { useRef } from "react";
import Footer from "../Footer";
import Forms from "../Forms";
import Header from "../Header";
import Hero from "../Hero";
import ScrollToTop from "../ScrollToTop";
import Services from "../Services";
import StartupSection from "../StartupSection";
import { data } from "./constants";
import DevelopmentProcess from "../DevelopmentProcess";

const BusinessIntelligenceComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"Data Analytics & Business Intelligence"} />
      <StartupSection content={data.development} formRef={formRef} />
      {/* <Services
        heading={data.services.heading}
        title={data.services.title}
        bgcolor={data.services.bgcolor}
        cols={data.services.cols}
        services={data.services.servicesData}
      /> */}
      <DevelopmentProcess heading={data.services.heading} data={data.services.servicesData}/>
      <StartupSection content={data.incubationfunding} />
      <Services
        heading={data.services1.heading}
        bgcolor={data.services1.bgcolor}
        services={data.services1.servicesData}
      />
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default BusinessIntelligenceComponent;
