import React from "react";
import ContactUsComponent from "../component/ContactUsComponent";

const ContactUs = () => {
  return (
    <div>
      <ContactUsComponent />
    </div>
  );
};

export default ContactUs;
