import AppDevelopmentComponent from "../component/AppDevelopment";

const AppDevelopment = () => {
  return (
    <div>
      <AppDevelopmentComponent />
    </div>
  );
};

export default AppDevelopment;
