import StartupComponent from "../component/StartupComponent";

const Startup = () => {
  return (
    <div>
      <StartupComponent />
    </div>
  );
};

export default Startup;
