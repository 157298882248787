import React from "react";
import aboutUs from "../../assets/gif/about_us.gif";

interface AboutusProps {
  content: {
    heading: string;
    paragraph: string[];
  }[];
}

const AboutUsinfo: React.FC<AboutusProps> = ({ content }) => {
  return (
    <div className="py-14 sm:px-[10vw] px-10 xl:px-52">
      <h1 className="md:text-[50px] text-lg font-semibold mb-10">ABOUT US</h1>
      {content.map((section, index) => (
        <div key={index}>
          <h2 className="font-semibold tex-[30px] my-5">{section.heading}</h2>
          <span>
            {section.paragraph.map((paragraph, pIndex) => (
              <div className="pb-5">
                <p className="text-gray-700 text-[15px]" key={pIndex}>
                  {paragraph}
                </p>
              </div>
              
            ))}
          </span>
        </div>
      ))}
    </div>
  );
};

export default AboutUsinfo;
