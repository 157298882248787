export const data = {
  heroContent: {
    imageUrl: "",
    heading: "Business Process Outsourcing Solutions",
    btncolor: "#591661",
    paragraph:
      "Offering cost-effective, flexible and scalable BPO services to clients, and helping ensure competitive advantage through the power of Business Process Outsourcing Solutions",
  },
  development: {
    btncolor: "#FFCD38",
    heading: "ACHIEVE COST EFFICIENCY",
    imageSrc: "",
    buttondirection: "center",
    description:
      "Driven by the desire to minimize costs, get access to professional talent, blend processes, control risk and focus more on core competencies, businesses have turned to BPO services as a mean of gaining enhanced competitiveness.",
  },

  services1: {
    heading: "CALL CENTER SERVICES",
    cols: 2,
    bgcolor: "#E0E0E0",
    title:
      "Missing a call means one potential customer lost! Our BPO services with its professionally efficient contact representative personnel will never let you disappointed. We offers customized inbound and outbound customer care services to get more business opportunities, profit and growth while retaining total customer satisfaction because your success means a lot to us.",
    servicesData: [
      {
        imageUrl: "",
        title: "REMOTE MANAGEMENT & MONITORING",
        bgcolor: "#4A4A4A",
        description:
          "The remote management and monitoring helps the managed IT service providers to remotely monitor the client endpoints, networks and computers.",
      },
      {
        imageUrl: "",
        title: "INFRASTRUCTURE MANAGEMENT",
        bgcolor: "#4A4A4A",
        description:
          "Our Infrastructure management services includes the optimizing of data center resources, customized workspace services, application and technical support.",
      },
    ],
  },
  services: {
    heading: "THIS IS HOW WE WILL ACCELERATE YOUR GROWTH",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: "",
        title: "RECRUITMENT PROCESS OUTSOURCING",
        description:
          "WOur BPO services provide flexible and scalable recruitment solutions which can include anything from sourcing and selection to onboarding individuals. We process tasks faster, more efficiently and at cost-effective rate than you could ever do in-house.",
      },
      {
        imageUrl: "",
        title: "IT SUPPORT",
        description:
          "From application development to network and server support, we aim to continuously deliver value in achieving the business goals of our clients. Helpdesk models are designed in order to meet the needs of individual customers for successful outsourcing outcome.",
      },
      {
        imageUrl: "",
        title: "FINANCE & ADMIN",
        description:
          "Following legal and financial obligations is unproductive but necessary, yet ourBPO services could cost you far less than either an in-house resource or any High Street accountancy firm.",
      },
      {
        imageUrl: "",
        title: "DATA PROCESSING",
        description:
          "With increasing costs towards employees salaries, overheads and infrastructural load, outsourcing your data processing needs our BPO services to be the only solution you are looking for.",
      },
      {
        imageUrl: "",
        title: "IWEB DEVELOPMENT",
        description:
          "Whether you want a fancy,appealing, new landing page for your startup business or hundreds of inner pages for your existing website, we have got you covered.",
      },
      {
        imageUrl: "",
        title: "MARKET RESEARCH & SURVEY",
        description:
          "We ensure that the research solutions provided to you are customized enough to meet clients' business objectives by critically analyzing the information input.",
      },
    ],
  },
  incubationfunding: {
    // buttondirection: "start",
    order: "md:order-2",
    // btncolor: "#FFCD38",
    title: "BPO FOR STARTUPS",
    imageSrc: "",
    description:
      "Startup would love to work with and only with the experts, but unfortunately they come with big prices.Our BPO startup outsourcing services team can handle your accounting, reporting and budget management solutions during the early phase. With this early BPO services, you can give complete focus on your main competencies during the crucial start-up period.",
  },
};
