import apple from "../../assets/static/apple.png";
import android from "../../assets/static/android.png";
import mobileDevelopmentJourney from "../../assets/static/mobile_development_journey.png";

export const data = {
  brands: {
    bgColor: "#fc6c14",
    title: "WE INFUSE YOUR BRAND'S ESSENCE INTO EVERY DESIGN",
    subtitle: "ELEVATING APPS TO NEW HEIGHTS OF USER-FRIENDLY FUNCTIONALITY",
    content: [
      "At TACTECH, our commitment to crafting mobile apps goes beyond the ordinary – we prioritize delivering exceptional user-friendliness coupled with a rich spectrum of functionalities.",
      "We understand that an app's success hinges on its ability to seamlessly engage users while offering a versatile range of features. Our team of experts meticulously designs and develops apps with an intuitive interface, ensuring users effortlessly navigate through every interaction.",
      "But we don't stop there. We believe that true innovation lies in the fusion of form and function. That's why our apps not only offer an inviting user experience but also encompass a diverse array of functionalities that cater to various needs.",
      "From smooth navigation to impactful features, every facet of our mobile apps is crafted to empower users and enrich their digital journey.",
    ],
  },
  progressivewebapp: {
    bgColor: "#14446d",
    title: "PROJECTING YOUR VISION INTO THEIR HANDS",
    subtitle: "FROM THE EMPOWERED, TO THE EMPOWERERS",
    content: [
      "At TACTECH, we are masters at translating your empowered vision into a tangible reality that rests in the hands of your users. We recognize that the journey from concept to execution is a critical one, demanding precision and creativity.",
      "Guided by a deep understanding of your objectives, our team of dedicated experts meticulously navigates every phase of development. From inception to prototyping to implementation, we synergize cutting-edge technology with innovative design, infusing vitality into your ideas. This synergy culminates in an immersive and captivating experience that not only captures attention but also forges enduring connections.",
      "Beyond delivering a product, we curate an enchanting journey, an experiential masterpiece that transforms your vision into a palpable, impactful reality, residing prominently within the grasp of those who matter most - your users.",
    ],
  },

  servicesData: {
    title: "WHERE iOS AND ANDROID UNITE",
    subtitle: "It's not just either/or anymore. It's cross-platform too!",
    imageUrl: "",
    services: [
      {
        logo: apple,
        title: "iOS MOBILE APPS",
        description:
          "We don't just develop iOS apps; we refine, test, and implement them with a keen focus on optimization. By incorporating client feedback, we enhance their functionality. At TACTECH, our pursuit of perfection is unwavering in every mobile application project we undertake. This dedication is why every iOS app we create is an exemplar of excellence.",
      },
      {
        logo: android,
        title: "ANDROID MOBILE APPS",
        description:
          "With an accomplished team of developers, TACTECH has ingeniously crafted cutting-edge Android apps. These applications not only enhance your brand image but also exceed your expectations. We possess a profound understanding of what it takes to deliver the best in mobile app development services, ensuring that our apps offer an unparalleled user experience.",
      },
    ],
  },
  journey: {
    title: "FROM YOUR IMAGINATION, TO THE USERS - THE JOURNEY",
    imageSrc: mobileDevelopmentJourney,
    description:
      "TACTECH's mobile app journey epitomizes our commitment to innovation and excellence. It starts with creative ideation, aligning concepts with the client's vision. Rigorous testing ensures top-tier quality and functionality. Deployment is a strategic integration into the digital landscape. The dedication doesn't end there; optimization is ongoing, leveraging feedback and tech advancements. This journey, marked by precision and relentless pursuit of excellence, ensures our apps deliver standout user experiences. It's a testament to our unwavering commitment to innovation and excellence.",
  },
};
