import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { getRandomAnimation } from "../../utils";
import Button from "../Button";
// import logo from "../../assets/gif/web_development_tablet.gif";

interface DevelopmentServicesProps {
  data: {
    imageUrl: string;
    title: string;
    description: string;
    animated: string;
    bgColor: string;
  };
  formRef?: React.RefObject<HTMLDivElement | null>;
}

const DevelopmentServices: React.FC<DevelopmentServicesProps> = ({
  data,
  formRef,
}) => {
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);
  //
  //
  const scrollToForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  //
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const { imageUrl, title, description, bgColor } = data;

  useEffect(() => {
    const handleScroll = () => {
      if (
        !hasScrolledToEnd &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 1500
      ) {
        AOS.refresh();
        setHasScrolledToEnd(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledToEnd]);

  return (
    <div
      style={{ backgroundColor: `${bgColor}` }}
      className={`h-full sm:px-[10vw] xl:px-52 px-10 py-16  m-auto overflow-hidden`}
    >
      <div
        className={`${
          title === "SHAPING E-COMMERCE INTO A GLOBAL STANDARD OF EXCELLENCE"
            ? "sm:grid-cols-2 sm:gap-5"
            : "sm:grid-cols-3 sm:gap-8"
        } grid   grid-cols-1 justify-center  m-auto  items-center`}
      >
        <div
          className={`flex ${
            title === "SHAPING E-COMMERCE INTO A GLOBAL STANDARD OF EXCELLENCE"
              ? "sm:order-2 order-1 md:justify-start justify-center"
              : "sm:order-2 order-1 lg justify-center"
          }  items-center h-full`}
          data-aos={hasScrolledToEnd ? "" : `fade-${getRandomAnimation()}`}
          data-aos-duration="1000"
        >
          <div>
            <img
              src={imageUrl}
              className={`${
                title ===
                "STUNNING ON YOUR LAPTOP, EQUALLY CAPTIVATING ON YOUR MOBILE"
                  ? "sm:h-[400px] xl:h-[500px]"
                  : "sm:h-[450px] xl:h-[500px]"
              } object-contain`}
              alt=""
            />
          </div>
        </div>
        <div
          className={`${
            title === "SHAPING E-COMMERCE INTO A GLOBAL STANDARD OF EXCELLENCE"
              ? "sm:order-1` order-2"
              : "sm:order-1 order-2  col-span-2"
          } flex flex-col items-center whitespace-pre-wrap `}
          data-aos={hasScrolledToEnd ? "" : `fade-${getRandomAnimation()}`}
          data-aos-offset="10"
          data-aos-easing="ease-in-sine"
        >
          <h1 className="md:text-[30px] text-left text-xl text-white font-semibold max-sm:mt-10 ">
            {title}
          </h1>
          {/* <div className="flex justify-start"> */}
          <p className="text-white mb- mt-5">{description}</p>
          {/* </div> */}
        </div>
      </div>
      {formRef && (
        <div className="flex justify-center mt-12">
          <Button
            text="Get Started"
            color="#FEA315"
            bgColor="#FFFFFF"
            borderColor="#FEA315"
            onClick={scrollToForm}
            hoverBgColor="#FEA315"
            hoverColor="#FFFFFF"
            // #00BFF3
          />
        </div>
      )}
    </div>
  );
};

export default DevelopmentServices;
