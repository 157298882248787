import { FaArrowRight } from "react-icons/fa";

interface ContentProps {
  imageUrl: string;
  title?: string;
  description?: string;
  links?: string;
}

interface DevelopmentProcessProps {
  data: ContentProps[];
  bgcolor?: string;
  heading?: string;
}

const DevelopmentProcess: React.FC<DevelopmentProcessProps> = ({
  data,
  bgcolor,
  heading,
}) => {
  return (
    <div className="py-14 sm:px-[10vw] px-10 xl:px-52 relative">
      <div className="flex sm:flex-row flex-col sm:gap-20 xl:gap-24    justify-between">
        <div
          className={` h-full pt-10 pb-10 w-full   sm:sticky top-10 fade-transition col-span-1  fade-transition `}
        >
          <div className="mt-20">
            <h1 className="md:text-[50px] text-2xl leading-9 uppercase font-bold lg:text-[60px] xs:text-[40px] sm:text-[40px]">
              THE <span className="text-[#0D709E]">TAC</span>
              <span className="text-[#FEA315]">TECH</span> WAY
            </h1>
            <p className="mt-4">
              — how we empower your journey towards the future.
            </p>
          </div>
        </div>
        {/* {isFixed && <div className={`${isFixed ? "col-span-1" : ""}`}></div>} */}
        <div className={`col-span-1 flex flex-col gap-10 w-full justify-end`}>
          <div className="flex-col gap-10">
            {data.map((val, index) => (
              <div className="custom-card p-3 flex gap-3 shadow-lg rounded-xl mb-10 group hover:translate-x-3 flex-col">
                <div className="flex items-center justify-start ">
                  <img
                    src={val.imageUrl}
                    className="object-contain opacity-75 xl:w-[20vw] w-[30vw] h-[10vh] max-w-[100px] min-w-[50px]"
                    alt="reload"
                  />
                  <h1 className="text-2xl my-auto text-start font-semibold mx-5">
                    {val.title}
                  </h1>
                </div>
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    {val.links != null && (
                      <FaArrowRight
                        color=""
                        size={"1.5rem"}
                        style={{ cursor: val.links ? "pointer" : "default" }}
                        className="my-auto -translate-x-2 group-hover:delay-300 group-hover:transition-all group-hover:ease-in-out group-hover:duration-150  group-hover:translate-x-3"
                      />
                    )}
                  </div>
                  <p className="mt-5 text-[14px] text-left leading-7 mx-5">
                    {val.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentProcess;
