import softwareDevelopment from "../../assets/gif/software_development.gif";
import devOps from "../../assets/gif/dev_ops.gif";
import businessProcessOutsourcing from "../../assets/gif/business_process_outsourcing.gif";
import tailouredSoftware from "../../assets/gif/software_engineering_tailoured.gif";
import agileDevelopment from "../../assets/gif/digital_marketing_agile_development.gif";
import strongCommunication from "../../assets/gif/digital_marketing_human_centered.gif"
import highEngagement from "../../assets/gif/data_analytics_work_together.gif";
import cuttingEdge from "../../assets/gif/laravel_api_development.gif";

export const data = {
  development: {
    heading: "SOFTWARE DEVELOPMENT",
    imageSrc: softwareDevelopment,
    buttondirection: "center",
    description:
      "Indulge in a world of limitless possibilities with TACTECH's bespoke software development services. From sports and finance to entertainment and technology, we've left our mark across diverse industries. Our experience is more than just a track record; it's a legacy of innovation waiting to elevate your project. Picture this: a collaborative team of experts breathing life into your vision, each skillfully crafted solution designed to leave an indelible impact. At TACTECH, we don't just offer software development; we offer solutions that you are looking for.",
  },
  services: {
    heading: "WHAT DO YOU SEEK?",
    bgcolor: "#14446d",
    servicesData: [
      {
        imageUrl: tailouredSoftware,
        title: "TAILOURED SOFTWARE",
        description:
          "Whether you're a startup taking your first steps or a well-established enterprise, our software development firm is your unwavering partner through every phase of the software development life cycle. From conceptualization and consulting to development and ongoing support, we offer comprehensive solutions. Our tailored software development services cater to all your specific needs, turning your desired vision into reality.",
      },
      {
        imageUrl: devOps,
        title: "DEVOPS MANAGEMENT",
        description:
          "Empower your mission-critical environments with enterprise-level support, seamlessly managing large-scale configurations through Infrastructure as Code (IaC). Our DevOps services allow you to keep your focus on core business objectives, while we expertly navigate the complexities of technology. Elevate your operations with a partnership that ensures both technical excellence and your business prosperity.",
        link: "/devops",
      },
      {
        imageUrl: businessProcessOutsourcing,
        title: "NEARSHORE OUTSOURCING",
        description:
          "Unlock the power of Nearshore Outsourcing with us an unprecedented experience of collaboration. Seamlessly bridging the gap between expertise and efficiency, we offer you the advantage of a dedicated team, just a stone's throw away. Let us transform your projects into milestones, leveraging our proximity, proficiency, and passion. Elevate your outsourcing experience with us, where distance is minimized, and results are maximized.",
      },
    ],
  },
  howwework: {
    heading: "THE TACTECH WAY",
    howWeWorkItems: [
      {
        title: "AGILE AND SCRUM",
        description:
          "Experience agile software development's power for swift, continuous delivery of valuable software. Guided by Scrum, we ensure seamless collaboration among stakeholders, enhancing results. Efficiency and excellence converge as we navigate your software journey.",
        imageUrl: agileDevelopment,
      },
      {
        title: "STRONG COMMUNICATION",
        description:
          "We believe in transparency, real-time communication and accessibility to keep our connection strong with you, no matter where you are. Our team of expert software developers is always just a call away, ready to offer their expertise at your convenience.",
        imageUrl: strongCommunication,
      },
      {
        title: "CUTTING-EDGE DEVELOPMENT",
        description:
          "Collaborating with us, guarantees your unwavering trust in a stable demo environment, expert QA and testing, readily accessible secure code, quick deployment, punctual deliveries of milestones, and a committed team that's dedicated to your ease and success.",
        imageUrl: cuttingEdge,
      },
      {
        title: "HIGH ENGAGEMENT",
        description:
          "This is where 'yours' becomes 'ours.' Taking the adage 'The client is always right' to new heights, we offer expert insights and advice, leaving no potential untapped. Our aim remains to seek our success in yours, establishing an ever-lasting relation of trust and prosperity.",
        imageUrl: highEngagement,
      },
    ],
  },
};
