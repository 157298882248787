import React, { useState } from "react";
import Button from "./Button";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { getRandomAnimation } from "../utils";
interface StartupProps {
  content: {
    heading?: string;
    imageSrc: string;
    description: string;
    title?: string;
    order?: string;
    buttondirection?: string;
    descriptionorder?: string;
    btncolor?: string;
    bgcolor?: string;
  };
  formRef?: React.RefObject<HTMLDivElement | null>;
}

const StartupSection: React.FC<StartupProps> = ({ content, formRef }) => {
  const {
    heading,
    imageSrc,
    description,
    title,
    buttondirection,
    btncolor = "#14446d",
    bgcolor = "#FFFFF",
    order = "order-1",
    descriptionorder = "order-1",
  } = content;
  //
  //
  //
  //

  const scrollToForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  //
  //
  //
  //
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (
        !hasScrolledToEnd &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 1500
      ) {
        AOS.refresh();
        setHasScrolledToEnd(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledToEnd]);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div
      className={`sm:px-[10vw] xl:px-52 px-10 py-14  overflow-hidden bg-[${bgcolor}]`}
      style={{ backgroundColor: bgcolor }}
    >
      {heading && (
        <div className="text-center">
          <h1
            className={`md:text-[40px] text-2xl text-center  font-semibold inline-block border-b-[3px] border-[${btncolor}] pb-5`}
          >
            {heading}
          </h1>
        </div>
      )}

      <div
        className={`grid justify-center items-center grid-cols-2 gap-10 sm:gap-16 ${
          heading ? "sm:mt-28 mt-16" : ""
        }`}
      >
        <span
          data-aos={!hasScrolledToEnd && `fade-${getRandomAnimation()}`}
          data-aos-offset="10"
          data-aos-easing="ease-in-sine"
          className={`col-span-2 sm:col-span-1 ${order} flex justify-center`}
        >
          <img
            className={`${
              heading === "STARTUPS" ? "sm:w-[250px] xl:w-[400px]" : "w-full"
            } h-full transform  object-fill transition-transform duration-200 ease-linear`}
            src={imageSrc}
            alt="reload"
          />
        </span>
        <span
          data-aos={!hasScrolledToEnd && `fade-${getRandomAnimation()}`}
          data-aos-easing="ease-in-sine"
          className={`col-span-2 sm:col-span-1  flex ${descriptionorder} flex-col gap-5 justify-center `}
        >
          {title && (
            <h3 className="font-semibold text-2xl text-left items-start">
              {title}
            </h3>
          )}
          <p>{description}</p>
        </span>
      </div>
      <div className={`flex  justify-center mt-16`}>
        {buttondirection && (
          <Button
            text="Get Started"
            color={btncolor}
            bgColor="transparent"
            borderColor={btncolor}
            onClick={scrollToForm}
            hoverBgColor={btncolor}
            hoverColor="#FFFFFF"
          />
        )}
      </div>
    </div>
  );
};

export default StartupSection;
