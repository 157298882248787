import { BrowserRouter, Routes, Route, createBrowserRouter } from "react-router-dom";
import WebDevelopment from "../pages/WebDevelopment";
import Home from "../pages/Home";
import AppDevelopment from "../pages/AppDevelopment";
import SoftwareDevelopment from "../pages/SoftwareDevelopment";
import Startup from "../pages/Startup";
import LaravelDevelopment from "../pages/LaravelDevelopment";
import EcommerceDevelopment from "../pages/EcommerceDevelopment";
import ManagedCloudHosting from "../pages/ManagedCloudHosting";
import ITResourcesManagement from "../pages/ITResourcesManagement";
import BusinessIntelligence from "../pages/BusinessIntelligence";
import BusinessProcessOutsourcing from "../pages/BusinessProcessOutsourcing";
import DigitalMarketing from "../pages/DigitalMarketing";
import ContactUs from "../pages/ContactUs";
import CloudServices from "../pages/CloudServices";
import AboutUS from "../pages/AboutUS";
// import ProjectComponent from "../component/Projects";
import Project from "../pages/Project";
// import BlogComponent from "../component/BlogComponent";
// import BlogDetail from "../component/BlogComponent/BlogDetail";

// const Routing = createBrowserRouter([
//   {
//     element: (
//       <Home />
//     ),
//     path: '/',
//   },
//   {
//     element: (
//       <WebDevelopment />
//     ),
//     path: '/web-design-development',
//   },
//   {
//     element: (
//       <AppDevelopment />
//     ),
//     path: '/mobile-app-development',
//   },
//   {
//     element: (
//       <SoftwareDevelopment />
//     ),
//     path: '/software-development',
//   },
//   {
//     element: (
//       <Startup />
//     ),
//     path: '/startups',
//   },
//   {
//     element: (
//       <LaravelDevelopment />
//     ),
//     path: '/laravel-development',
//   },
//   {
//     element: (
//       <EcommerceDevelopment />
//     ),
//     path: '/ecommerce-development',
//   },
//   {
//     element: (
//       <ManagedCloudHosting />
//     ),
//     path: '/cloud-management-hosting',
//   },
//   {
//     element: (
//       <ITResourcesManagement />
//     ),
//     path: '/it-resource-management',
//   },
//   {
//     element: (
//       <BusinessIntelligence />
//     ),
//     path: '/data-analytics-business-intelligence',
//   },
//   // {
//   //   element: (
//   //     <BusinessProcessOutsourcing />
//   //   ),
//   //   path: '/business-process-outsourcing',
//   // },
//   {
//     element: (
//       <DigitalMarketing />
//     ),
//     path: '/digital-marketing',
//   },
//   {
//     element: (
//       <CloudServices />
//     ),
//     path: '/devops',
//   },
//   {
//     element: (
//       <ContactUs />
//     ),
//     path: '/contact-us',
//   },
//   {
//     element: (
//       <AboutUS />
//     ),
//     path: '/about-us',
//   },
// ]);

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/web-design-development" element={<WebDevelopment />} />
        <Route path="/mobile-app-development" element={<AppDevelopment />} />
        <Route path="/software-development" element={<SoftwareDevelopment />} />
        <Route path="/startups" element={<Startup />} />
        <Route path="/laravel-development" element={<LaravelDevelopment />} />
        <Route
          path="/ecommerce-development"
          element={<EcommerceDevelopment />}
        />
        <Route
          path="/cloud-management-hosting"
          element={<ManagedCloudHosting />}
        />
        <Route
          path="/it-resource-management"
          element={<ITResourcesManagement />}
        />
        <Route
          path="/data-analytics-business-intelligence"
          element={<BusinessIntelligence />}
        />
        <Route
          path="/business-process-outsourcing"
          element={<BusinessProcessOutsourcing />}
        />
        <Route path="/digital-marketing" element={<DigitalMarketing />} />
        <Route path="/devops" element={<CloudServices />} />
        {/* Contact US  */}
        <Route path="/contact-us" element={<ContactUs />} />
        {/* <Route path="/blogdetail" element={<BlogDetail />} />
        <Route path="/blog" element={<BlogComponent />} /> */}
        <Route path="/about-us" element={<AboutUS />} />
        <Route path="/projects" element={<Project />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
