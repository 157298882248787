import React, { useRef } from "react";
import ScrollToTop from "../ScrollToTop";
import Header from "../Header";
import Hero from "../Hero";
import Breadcrumb from "../Breadcrumb";
import { data } from "./constants";
import StartupSection from "../StartupSection";
import Forms from "../Forms";
import Footer from "../Footer";
// import Services from "../Services";
import DevelopmentProcess from "../DevelopmentProcess";
import Swiper from "../Swiper";

const ManagedCloudHostingComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="relative">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"CLOUD MANAGEMENT & HOSTING"} />
      <StartupSection content={data.development} formRef={formRef} />
      {/* <Services
        heading={data.services.heading}
        title={data.services.title}
        bgcolor={data.services.bgcolor}
        services={data.services.servicesData}
      /> */}
      <Swiper
        heading={data.services.heading}
        title={data.services.title}
        bgcolor={data.services.bgcolor}
        services={data.services.servicesData}
      />
      {/* <Services
        heading={data.benefits.heading}
        bgcolor={data.benefits.bgcolor}
        services={data.benefits.servicesData}
      /> */}
      <DevelopmentProcess
        heading={data.benefits.heading}
        data={data.benefits.servicesData}
        bgcolor={data.benefits.bgcolor}
      />
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default ManagedCloudHostingComponent;
