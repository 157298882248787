import React, { useRef } from "react";
import ScrollToTop from "../ScrollToTop";
import Header from "../Header";
import Hero from "../Hero";
import Breadcrumb from "../Breadcrumb";
import StartupSection from "../StartupSection";
import Forms from "../Forms";
import Footer from "../Footer";
import { data } from "./contants";

const ContactUsComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);
  return (
    <div>
      {" "}
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"IT Resource Management"} />
      <StartupSection content={data.development} />
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default ContactUsComponent;
