import React, { useRef } from "react";
import Breadcrumb from "../Breadcrumb";
import ScrollToTop from "../ScrollToTop";
import Header from "../Header";
import Hero from "../Hero";
import { data } from "./constants";
import StartupSection from "../StartupSection";
import Forms from "../Forms";
import Footer from "../Footer";
import Services from "../Services";

const BusinessProcessOutsourcingComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="overflow-hidden">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero image={data.heroContent.imageUrl} content={data.heroContent} />
      </div>
      <Breadcrumb title={"Bussiness Process OutSourcing"} />
      <StartupSection content={data.development} formRef={formRef} />

      <StartupSection content={data.incubationfunding} />
      <Services
        heading={data.services1.heading}
        title={data.services1.title}
        bgcolor={data.services1.bgcolor}
        services={data.services1.servicesData}
        cols={data.services1.cols}
      />
      <Services
        heading={data.services.heading}
        bgcolor={data.services.bgcolor}
        services={data.services.servicesData}
      />
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default BusinessProcessOutsourcingComponent;
