import React, { useRef } from "react";
import Forms from "../Forms";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";
import Header from "../Header";
import Hero from "../Hero";
import Breadcrumb from "../Breadcrumb";
import { data } from "./constants";
import AboutUsinfo from "./AboutUsinfo";

const AboutusComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);
  return (
    <div>
      <div className="overflow-hidden">
        <ScrollToTop />
        <div className="relative">
          <Header formRef={formRef} />
          <Hero />
        </div>
        <Breadcrumb title={"About US"} />
        <AboutUsinfo content={data.aboutussinfo} />
        <Forms ref={formRef} />
        <Footer />
      </div>
    </div>
  );
};

export default AboutusComponent;
