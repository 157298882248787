import React from "react";
import { AiFillHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

interface BreadcrumbProps {
  title: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const navigate = useNavigate();
  return (
    <nav className="font-semibold bg-[#E1DFDF] py-5">
      <div className="border-[#3E3E3E] border-[1px] mx-10 rounded-[6px] inline-block justify-start text-left">
        <ol className="list-none gap-4 p-0 justify-start flex">
          <li className="flex items-center bg-[#3E3E3E] border-[#3E3E3E] border-[1px] py-2 px-3 rounded">
            <p
              className="transition duration-300 ease-in-out cursor-pointer"
              onClick={() => navigate("/")}
            >
              <AiFillHome color="white" />
            </p>
          </li>
          <li className="flex items-center p-2 rounded-full">
            <p className="transition duration-300 ease-in-out cursor-pointer">
              <div className="relative">
                <span className="relative uppercase pr-3">{props.title}</span>
              </div>
            </p>
          </li>
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumb;
