import React from "react";
import LaravelDevelopmentComponent from "../component/LaravelDevelopment";

const LaravelDevelopment = () => {
  return (
    <div>
      <LaravelDevelopmentComponent />
    </div>
  );
};

export default LaravelDevelopment;
