import React from "react";
import BusinessProcessOutsourcingComponent from "../component/BusinessProcessOutsourcingComponent";

const BusinessProcessOutsourcing = () => {
  return (
    <div>
      <BusinessProcessOutsourcingComponent />
    </div>
  );
};

export default BusinessProcessOutsourcing;
