import React from "react";
import ManagedCloudHostingComponent from "../component/ManagedCloudHosting";

const ManagedCloudHosting = () => {
  return (
    <div>
      <ManagedCloudHostingComponent />
    </div>
  );
};

export default ManagedCloudHosting;
