import React from "react";
import Button from "./Button";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

interface ServiceItemProps {
  imageUrl: string;
  title: string;
  description: string;
  index?: number;
  hasScrolledToEnd?: boolean;
  link?: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({
  imageUrl,
  title,
  description,
  index,
  hasScrolledToEnd,
  link
}) => {

  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col text-black"
      data-aos={
        !hasScrolledToEnd &&
        index !== undefined &&
        (index === 0 || index === 3
          ? `fade-right`
          : index === 2 || index === 5
          ? `fade-left`
          : "")
      }
      data-aos-offset="10"
      data-aos-duration="1000"
      data-aos-easing="ease-in-sine"
    >
      <div className="flex justify-center my-10 ">
        <img src={imageUrl} className="object-cover max-h-44"  alt="" />
      </div>
      <h1 className="text-2xl text-center font-semibold">{title}</h1>
      <p className="mt-5 text-[14px] text-center leading-7">{description}</p>
      {link != null && 
        <div className="flex justify-center mt-5">
          <Button
            text="Learn More"
            color="#FFFFFF"
            bgColor="#14446d"
            borderColor="#14446d"
            hoverBgColor="#FFFFFF"
            hoverColor="#14446d"
            onClick={() => {navigate(link)}}
          />
        </div>
      
      }
      
    </div>
  );
};

interface OfferProps {
  heading: string;
  services: ServiceItemProps[];
}

const Offer: React.FC<OfferProps> = ({ heading, services }) => {
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !hasScrolledToEnd &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 1500
      ) {
        AOS.refresh();
        setHasScrolledToEnd(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledToEnd]);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="sm:px-[10vw] px-10 xl:px-52 py-14 bg-[#F5F5F5] max-h-full overflow-x-hidden">
      <div className="text-center">
        <h1 className="md:text-[40px] text-black text-2xl text-center font-semibold inline-block border-b-[3px] border-[#00B1FF]  pb-5">
          {heading}
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-20">
        {services.map((service, index) => (
          <ServiceItem
            key={index}
            index={index}
            hasScrolledToEnd={hasScrolledToEnd}
            imageUrl={service.imageUrl}
            title={service.title}
            description={service.description}
            link={service.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Offer;
