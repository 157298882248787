import { useNavigate } from "react-router-dom";
import logo from "../../assets/static/logo_black.png";
import {
  HiMail,
  HiMap,
  HiOutlineMail,
  HiOutlinePhone,
  HiPhone,
} from "react-icons/hi";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { FaAddressBook, FaPinterestSquare } from "react-icons/fa";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#fafafa] p-8 shadow sm:px-[10vw] px-10 xl:px-52 py-14">
      <div className="grid sm:grid-cols-2 grid-cols-1  gap-24">
        <div className="flex flex-col gap-10">
          <img src={logo} alt="" onClick={() => navigate("/")} width={100} />
          <div className="flex flex-col gap-3">
            <p className="text-left">
              360° solutions, tactically tailoured for you.
            </p>
            <p className="text-left">
              This is where innovation meets excellence. With a passion for
              pushing technological boundaries, we're dedicated to crafting
              solutions that redefine industries. Explore our diverse range of
              services and discover how we can collaborate to drive your
              business forward. Our team of experts is committed to delivering
              tailored strategies that align with your unique goals. Join us on
              this journey of transformation and let's shape the future
              together.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <h1 className="md:text-[40px] text-lg text-left font-semibold">
            CONTACT US
          </h1>
          <div className="flex gap-5 mt-5">
            <span>
              <HiMail size={"2rem"} color="black" />
            </span>
            <a
              href="mailto:mohd2000omer@gmail.com"
              className="my-auto text-left"
            >
              omer@tactech.tech
            </a>
          </div>
          <div className="flex gap-5">
            <span>
              <HiOutlineMail size={"2rem"} color="black" />
            </span>
            <a
              href="mailto:mohd2000omer@gmail.com"
              className="my-auto text-left"
            >
              mohd2000omer@gmail.com
            </a>
          </div>
          <div className="flex gap-5">
            <span>
              <HiMap size={"2rem"} color="black" />
            </span>
            <a className="my-auto text-left">
              7901 4th St N STE 300 St. Petersburg, FL 33702
            </a>
          </div>
          <div className="flex gap-5">
            <span>
              <HiOutlinePhone size={"2rem"} color="black" />
            </span>
            <a className="my-auto text-left">+1 (305) 990-1567</a>
          </div>
          <div className="flex gap-5">
            <span>
              <HiPhone size={"2rem"} color="black" />
            </span>
            <a className="my-auto text-left">+92 321 8461955</a>
          </div>
        </div>
      </div>
      <div className="flex gap-5 flex-wrap mt-10">
        {/* <p
          className="whitespace-nowrap cursor-pointer"
          onClick={() => navigate("/blog")}
        >
          Blog
        </p> */}
        {/* <p className="whitespace-nowrap">Careers</p> */}
        <p
          className="whitespace-nowrap cursor-pointer"
          onClick={() => navigate("/contact-us")}
        >
          Contact Us
        </p>
        {/* <p className="whitespace-nowrap">Privacy Policy</p> */}
      </div>
      <hr className="border-[#198754] border-[0.1px] rounded-full my-16" />
      <div className="flex flex-col sm:flex-row gap-10 sm:justify-between">
        <p className="my-auto text-left">
          © {new Date().getFullYear()} All Rights Reserved By TACTECH LLC
        </p>
        {/* <div className="flex gap-5">
          <AiFillFacebook size={"2rem"} />
          <AiFillLinkedin size={"2rem"} />
          <AiFillInstagram size={"2rem"} />
          <FaPinterestSquare size={"2rem"} />
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
