import React from "react";
import CloudServicesComponent from "../component/CloudServicesComponent";

const CloudServices = () => {
  return (
    <div>
      <CloudServicesComponent />
    </div>
  );
};

export default CloudServices;
