import itResourceManagement from "../../assets/gif/it_resource_management.gif";
import cloudServices from "../../assets/gif/it_resource_cloud_services.gif";
import disasterRecovery from "../../assets/gif/it_resource_disaster_recovery.gif";
import securityServices from "../../assets/gif/it_resource_security_services.gif";
import codeAudit from "../../assets/gif/laravel_code_audit.gif";
import itResourcePlanning from "../../assets/static/it_resource_resource_planning.png";

export const data = {
  development: {
    btncolor: "#F21860",
    heading: "IT RESOURCE MANAGEMENT",
    imageSrc: itResourceManagement,
    buttondirection: "center",
    description:
      "TACTECH efficiently manages a pool of skilled IT resources available for hire, seamlessly integrating them into your teams. Our adept team members bring specialized expertise to enhance projects, contributing seamlessly to your objectives. This flexible resource allocation ensures projects are executed with proficiency, meeting diverse business needs effectively.",
  },
  resourcePlanning: {
    // buttondirection: "start",
    // btncolor: "#F21860",
    title: "EFFICIENT RESOURCE ALLOCATION",
    imageSrc: itResourcePlanning,
    description:
      "At TACTECH, we specialize in efficient resource allocation to perfectly align with your unique requirements. Whether you're seeking to hire one or multiple resources for your team, we provide tailored solutions. Our meticulous process ensures that each professional possesses the specific expertise needed, ensuring seamless integration and optimal productivity. Trust us to deliver the best-fit solution for your projects.",
  },
  services: {
    heading: "",
    cols: 4,
    bgcolor: "#F5F5F5",
    title:
      "",
    servicesData: [
      {
        imageUrl: codeAudit,
        title: "CODE AUDIT & BUG SWATTING",
        description:
          "TACTECH offers specialized IT resources for precise tasks like code auditing and bug resolution, as part of your team. Our skilled professionals bring a sharp focus to identify and rectify coding discrepancies, ensuring optimal system performance. By hiring our experts, businesses can swiftly address technical issues, enhancing the robustness and reliability of their digital solutions.",
      },
      {
        imageUrl: cloudServices,
        title: "CLOUD MANAGEMENT & DEVOPS",
        description:
          "We provide access to proficient IT resources specialized in cloud management and DevOps. Our experts streamline operations, ensuring seamless deployment and scalability. By hiring our skilled professionals to be a part of your team, you can leverage the power of cloud technologies and implement DevOps practices for enhanced efficiency, security, and agility in your operations.",
      },
      {
        imageUrl: disasterRecovery,
        title: "OPTIMIZATION & SECURITY",
        description:
          "TACTECH offers dedicated IT resources for code optimization and fortified security, to join your team. Our experts meticulously refine code for improved efficiency and performance. Simultaneously, they implement robust security measures to safeguard against potential vulnerabilities. By hiring our skilled professionals, businesses can fortify their digital assets, ensuring a resilient and high-performing technology infrastructure.",
      },
      {
        imageUrl: securityServices,
        title: "SOFTWARE DEVELOPMENT",
        description:
          "We provide the opportunity to hire proficient IT resources specialized in web and mobile software development. Our experts bring extensive knowledge to your teams, to craft tailored solutions that meet your unique requirements. With a keen focus on innovation and quality, our professionals ensure the delivery of high-performing software that drives your business forward in the digital landscape.",
      },
      
    ],
  },
  // services1: {
  //   heading: "THIS IS HOW WE WILL ACCELERATE YOUR GROWTH",
  //   cols: 4,
  //   bgcolor: "#F5F5F5",
  //   title:
  //     "We create lifetime memorable experiences between your brand and the customers, to grow your business virtually with our IT Support & Management services.",
  //   servicesData: [
  //     {
  //       imageUrl: "https://arpatech.com/img/cms/i1.png",
  //       title: "REMOTE MANAGEMENT & MONITORING",
  //       description:
  //         "The remote management and monitoring helps the managed IT service providers to remotely monitor the client endpoints, networks and computers.",
  //     },
  //     {
  //       imageUrl: "https://arpatech.com/img/cms/i2.png",
  //       title: "INFRASTRUCTURE MANAGEMENT",
  //       description:
  //         "Our Infrastructure management services includes the optimizing of data center resources, customized workspace services, application and technical support.",
  //     },
  //     {
  //       imageUrl: "https://arpatech.com/img/cms/i3.png",
  //       title: "OUTSOURCED NOC SOLUTIONS",
  //       description:
  //         "Are you looking for outsourced NOC solutions which doesn't break the bank? Well, being an IT service provider, we serve our clients globally as well with our 24*7 outsourced NOC services.",
  //     },
  //     {
  //       imageUrl: "https://arpatech.com/img/cms/i4.png",
  //       title: "PWELL STRUCTURED MANAGEMENT REPORTS",
  //       description:
  //         "We provide you a structured Management reporting system, essential for monitoring the mission of any organization and to make it successful.",
  //     },
  //     {
  //       imageUrl: "https://arpatech.com/img/cms/i5.png",
  //       title: "SEAMLESS INFRASTRUCTURE STREAMING",
  //       description:
  //         "Since seamless infrastructure streaming has become a basic requirement, so we design and optimize the networks to provide an excellent quality of services.",
  //     },
  //     {
  //       imageUrl: "https://arpatech.com/img/cms/i6.png",
  //       title: "INFORMATION SECURITY MANAGEMENT",
  //       description:
  //         "Our Managed IT services gives you the best security services to minimize the risk and ensures the continuity by proactively limiting the impact of a security breach",
  //     },
  //   ],
  // },
  
};
