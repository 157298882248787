import ecommerece_wesite from "../../assets/gif/ecommerce_website.gif";
import ecommerece_laptop from "../../assets/gif/ecommerce_laptop.gif";
import shopify from "../../assets/static/shopify.png";
import wordpress from "../../assets/static/wordpress.png";
import woocommerce from "../../assets/static/woocommerce.png";
import squareSpace from "../../assets/static/square_space.png";
import magento from "../../assets/static/magento.png";
import unwaveringAssistance from "../../assets/gif/laravel_unwavering_assistance.gif"
import secureDataMigrations from "../../assets/gif/ecommerce_secure_data_migration.gif"
import smoothUserExperience from "../../assets/gif/ecommerce_smooth_user_experience.gif"
import seamlessResponsiveness from "../../assets/gif/ecommerce_seamless_responsiveness.gif"
import cartOptimization from "../../assets/static/ecommerce_cart_optimization.png";
import engagingProducts from "../../assets/static/ecommerce_engaging_products.png";
import securePayments from "../../assets/static/ecommerce_secure_payments.png";
import socialMediaMarketing from "../../assets/static/ecommerce_social_media.png";
import sleekDesign from "../../assets/static/ecommerce_sleek_design.png";
import appDevelopment from "../../assets/static/ecommerce_app_development.png";

export const data = {
  development: {
    btncolor: "#D1544A",
    heading: "SEAMLESS E-COMMERCE SOLUTIONS, EXCEPTIONAL EXPERIENCES",
    imageSrc: ecommerece_wesite,
    buttondirection: "center",
    description:
      "E-commerce has evolved from basic online stores to sophisticated, user-centric platforms. At TACTECH, we're driving this evolution further with innovative technologies and data-driven strategies, shaping a future of engaging, secure, and personalized online shopping experiences. With a dedicated focus on seamless user interfaces and cutting-edge features, we're committed to revolutionizing the e-commerce landscape.",
  },
  apratechcomesin: {
    // btncolor: "#591661",
    bgcolor: "#F5F5F5",
    heading: "HOW WE HELP",
    imageSrc: ecommerece_laptop,
    // buttondirection: "end",
    description:
      "An effective e-commerce website hinges on key components such as a user-friendly shopping cart system, secure payment gateways, intuitive navigation, and engaging product displays. Moreover, responsive design ensures accessibility across devices, while robust search functionality and clear calls-to-action enhance user experience. These elements collectively create a seamless and trustworthy online shopping environment, driving conversions and customer satisfaction.",
  },
  services: {
    heading: "OUR EXPERIENCE WITH TECHNOLOGY",
    bgcolor: "#461757",
    title:
      "Being a hub for technology & research, we believe in exploring different verticals while working on a particular industry. For example, if we talk about the Ecommerce Sector, then we not only work on custom made systems, but have also mastered development on the following platforms:",
    servicesData: [
      {
        imageUrl: shopify,
        description: "Essential for seamless, scalable online stores. User-friendly interface & customizable features are key."
      },
      {
        imageUrl: wordpress,
        description: "Versatile CMS powering numerous e-commerce sites. Plugin ecosystem, especially WooCommerce, vital for online storefronts."
      },
      {
        imageUrl: woocommerce,
        description: "Critical for businesses using WordPress. Offers diverse customization options, integrating seamlessly for complete e-commerce solutions."
      },
      {
        imageUrl: squareSpace,
        description: "Vital for visually appealing websites. Known for drag-and-drop functionality & beautiful templates, a go-to for design-focused businesses."
      },
      {
        imageUrl: magento,
        description: "Crucial for larger enterprises with complex product catalogs. Known for scalability & robust features, a top choice for extensive inventories."
      },
    ],
  },
  benefits: {
    heading: "BENEFITS OF WORKING WITH US",
    title:
      "One of the first-rate players in ecommerce development throughout Pakistan Trained to handle the dynamics of responsive in addition to open source technologies Complete adherence to best practices and delivery in accordance to timeline commitments A whole answer on the way to combine the inventory and the backend with the frontend creates for a more suitable performance in business activities and user experience.",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: seamlessResponsiveness,
        title: "SEAMLESS RESPONSIVENESS",
        description:
          "We specialize in creating e-commerce websites that are flawlessly responsive on all devices, guaranteeing a seamless user experience.",
      },
      {
        imageUrl: smoothUserExperience,
        title: "SMOOTH USER EXPERIENCE",
        description:
          "We prioritize a seamless user experience through meticulous design and rigorous testing, ensuring every interaction is intuitive and efficient.",
      },
      {
        imageUrl: secureDataMigrations,
        title: "SECURE DATA MIGRATION",
        description:
          "We prioritize data security during migrations, if they're required, employing encryption and best practices to safeguard your valuable information.",
      },
      {
        imageUrl: unwaveringAssistance,
        title: "UNWAVERING ASSISTANCE",
        description:
          "In e-commerce, constant support and maintenance are vital for seamless operations and growth. Our dedicated team ensures round-the-clock assistance, swiftly addressing any potential issues to keep your online store running smoothly.",
      },
    ],
  },
  ecommercewebsite: {
    heading: "",
    title:
      "TACTECH is a disciplined service issuer for years within the e-trade section. Our team possesses diverse enjoy in coping with distinct industries and their eCommerce web sites. It’s by miles one of the most dependable ecommerce website development agency to offer website revamp and development from scratch for its clients. The ecommerce layout and improvement will appear exceptionally beneficial in your enterprise. The custom ecommerce website development is a need to for every commercial enterprise now. You can't deny the requirement of internet ecommerce development in the current digital age. The professional ecommerce web development company will generate the shopping website as in line with the needs of the clients. if you have an eCommerce internet site, online shop, or utility development service, we can build it in short deadlines and within your defined quidelines. TACTECH is a surprisingly reliable ecommerce development company across Pakistan. Get an ecommerce purchasing cart improvement performed by us and witness the big on-line sales in a jiffy.",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: cartOptimization,
        title: "CART OPTIMIZATION",
        description:
          "Our seamless cart experience ensures effortless navigation, intuitive product management, and secure checkout processes, guaranteeing a smooth shopping journey that boosts customer satisfaction and conversions.",
      },
      {
        imageUrl: engagingProducts,
        title: "ENGAGING PRODUCT DISPLAYS",
        description:
          "We craft captivating product displays with visually appealing images, compelling descriptions, and intuitive navigation, ensuring a seamless and engaging shopping experience that entices customers and drives conversions.",
      },
      {
        imageUrl: securePayments,
        title: "SECURE PAYMENTS",
        description:
          "We expertly integrate secure payment gateways, providing customers with a hassle-free and trustworthy transaction experience. Our solutions ensure data security and enable a smooth checkout for customer satisfaction.",
      },
      {
        imageUrl: socialMediaMarketing,
        title: "SOCIAL MEDIA MARKETING",
        description:
          "Alongside e-commerce development, we offer integrated marketing solutions on platforms like Facebook, Instagram, and Twitter. We optimize ad campaigns, content, and engagement to elevate your brand's online presence.",
        link: "/digital-marketing"
      },
      {
        imageUrl: sleekDesign,
        title: "SLEEK DESIGN",
        description:
          "Our experts craft sleek designs, combining intuitive navigation, and engaging visuals for an exceptional user experience. This meticulous approach guarantees an attractive and functional platform that captivates customers.",
      },
      {
        imageUrl: appDevelopment,
        title: "MOBILE APP DEVELOPMENT",
        description:
          "We extend our services with custom mobile app development, seamlessly integrating with your e-commerce platform. This ensures a cohesive shopping experience, driving engagement and conversions across iOS and Android platforms.",
        link: "/mobile-app-development"
      },
    ],
  },
};
