import startUp from "../../assets/gif/startup.gif";
import startUpWebDevelopment from "../../assets/gif/startup_web_development.gif";
import startUpMobileDevelopment from "../../assets/gif/startup_app_development.gif";
import startUpCloudManagement from "../../assets/gif/cloud_management_hosting.gif";
import scalability from "../../assets/gif/it_resource_management.gif";
import businessDevelopment from "../../assets/gif/data_analytics_smarter_decisions.gif";
import resources from "../../assets/gif/digital_marketing_customer_insights.gif";

export const data = {
  startup: {
    heading: "STARTUPS",
    buttondirection: "center",
    imageSrc: startUp,
    description:
      "Empowering Startups to Reach New Heights! Through a collaborative approach, we guide entrepreneurs on their startup journey, refining business models and monetization strategies to pave the way for sustained growth.",
  },
  Offer: {
    heading: "WHAT DO YOU SEEK?",
    servicesData: [
      {
        imageUrl: startUpWebDevelopment,
        title: "WEB DESIGN & DEVELOPMENT",
        description:
          "A website is a digital storefront that introduces your startup to the world, acting as a 24/7 accessible platform to showcase your products or services. It establishes credibility, boosts brand visibility, and opens doors to a global audience, crucial for driving initial traction and fostering trust.",
        link: "/web-design-development"
      },
      {
        imageUrl: startUpMobileDevelopment,
        title: "MOBILE APP DEVELOPMENT",
        description:
          "A mobile app provides a direct and personalized connection between your startup and users, enhancing engagement and brand loyalty. It offers a convenient gateway for users to access your offerings on the go, driving user retention and amplifying your startup's market presence, which is highly valuable.",
        link: "/mobile-app-development"
      },
      {
        imageUrl: startUpCloudManagement,
        title: "CLOUD MANAGEMENT & HOSTING",
        description:
          "An efficient and secure cloud infrastructure empowers startups with scalability and reliability, ensuring seamless performance even during periods of rapid growth. It frees up valuable time and resources, reduces technical burden, allowing startups to focus more on innovation and core business strategies.",
        link: "/cloud-management-hosting"
      },
    ],
  },
  services: {
    heading: "HOW WE HELP",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: scalability,
        title: "SCALABILITY",
        description:
          "At TACTECH, we fuel startup scalability through solutions designed to evolve with your growth. With our strategic approach and adaptable architectures, we empower you to seize opportunities confidently, knowing that your systems can effortlessly handle increased demands. Our commitment is to provide the technical foundation that enables your startup to flourish and expand in a dynamic business landscape.",
      },
      {
        imageUrl: businessDevelopment,
        title: "BUSINESS DEVELOPMENT",
        description:
          "At TACTECH, we're your dedicated ally in propelling comprehensive business development. Our array of services is meticulously curated to guide startups at every step of their journey. From innovative digital solutions to streamlining operations, our expertise is the cornerstone of your success. With a pulse on emerging trends and a commitment to tailored strategies, we empower your business to set industry benchmarks.",
      },
      {
        imageUrl: resources,
        title: "RESOURCES",
        description:
          "TACTECH is a dedicated enabler for startups, offering a rich pool of development resources. Our seasoned experts become an extension of your team, bringing creative insights to your projects. With our support, startups gain the capacity to accelerate their development timelines, and navigate complex challenges. We're your reliable partner in driving startup success through robust development resources.",
      },
    ],
  },
};
