import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { getRandomAnimation } from "../../utils";

interface BrandProps {
  data: {
    title: string;
    subtitle?: string;
    content: string[];
    bgColor: string;
  };
}

const Brand: React.FC<BrandProps> = ({ data }) => {
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !hasScrolledToEnd &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 1500
      ) {
        AOS.refresh();
        setHasScrolledToEnd(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledToEnd]);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const { title, subtitle, content, bgColor } = data;

  return (
    <div
      style={{ backgroundColor: `${bgColor}` }}
      className={`grid grid-cols-1 sm:px-20 px-10 sm:grid-cols-3 gap-4  max-h-full overflow-x-hidden`}
    >
      <div
        className={`${
          title === "PROJECTING YOUR VISION INTO THEIR HANDS"
            ? "fussion_fullwidth1 sm:order-2"
            : "fussion_fullwidth2 sm:order-2"
        }`}
      ></div>

      <div className="sm:px-20 px-10 py-28 sm:col-span-2 items-center sm:order-1">
        <div
          className="flex flex-col"
          data-aos={hasScrolledToEnd ? "" : `fade-${getRandomAnimation()}`}
          data-aos-duration="1000"
        >
          <h1 className="sm:text-[40px] leading-10 text-2xl font-semibold text-white text-left">
            {title}
          </h1>
          <p className="sm:text-[26px] text-xl mt-5 text-white">{subtitle}</p>
          <hr className="bg-white border-white border-[1px] w-full my-5" />
          <span className="mt-5 flex flex-col items-center gap-4 text-white text-left">
            {content.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Brand;
