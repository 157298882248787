import React from "react";
import { projects } from "./constant";
import ProjectModal from "./ProjectModal";
import { BsArrowRightShort } from "react-icons/bs";

const Card = () => {
  return (
    <div className="py-14 sm:px-[10vw] px-10 xl:px-52">
      <div className="grid gap-10 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
        {projects.map((val, index) => (
          <div
          className={`border-[${
            index % 2 === 0 ? "#0D709E" : "#FEA315"
          }] border-[1px] rounded-lg min-h-[500px] xs:min-h-[300px] md:min-h-[420px] xl:min-h-[500px] flex justify-center`}
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              backgroundColor: val.bgColor,
            }}
          >
            <img
              className="w-full my-auto object-contain"
              src={val.imgurl}
              style={{ maxWidth: "200px", minHeight: "200px" }}
              alt={`${val.title}`}
            />
          </div>
          <div
            className={`flex flex-col p-4 hover:bg-[${
              index % 2 === 0 ? "#0D709E" : "#FEA315"
            }] delay-100 animate-fade duration-200 hover:text-white text-left`}
          >
            <h1 className="font-semibold mb-3 break-all" style={{fontSize: '18px'}}>{val.title}</h1>
            <small style={{fontSize: '14px'}}>{val.description}</small>
            <button className="text-left flex mt-2 my-auto flex" style={{alignItems: 'start', paddingTop: '1rem'}}>
              <ProjectModal project={val} />
            </button>
          </div>
        </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
