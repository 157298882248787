import { useState, useEffect } from "react";
import { getRandomAnimation } from "../../utils";
import AOS from "aos";
import "aos/dist/aos.css";
interface laravelComponentProps {
  content: {
    heading: string;
    imageSrc: string;
    description: string[];
  };
}

const LaravelComponent: React.FC<laravelComponentProps> = ({ content }) => {
  const { heading, imageSrc, description } = content;
  const [showAll, setShowAll] = useState(false);
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        !hasScrolledToEnd &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 1500
      ) {
        AOS.refresh();
        setHasScrolledToEnd(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledToEnd]);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="sm:px-[10vw] px-10 xl:px-52 pb-14">
      {" "}
      <div
        className={`grid justify-center grid-cols-2 gap-10 sm:gap-16 ${
          heading ? "sm:mt-28 mt-16" : "mt-10"
        }`}
      >
        <span
          data-aos={!hasScrolledToEnd && `fade-${getRandomAnimation()}`}
          data-aos-offset="10"
          data-aos-easing="ease-in-sine"
          className={`col-span-2 sm:col-span-1 text-left flex  flex-col gap-5 justify-center items-center sm:mt-10`}
        >
          <h1 className="md:text-[30px] text-xl font-semibold">{heading}</h1>
          {/* <ul className="list-disc list-inside flex flex-col justify-start ">
            {subtitle.map((val, index) => (
              <li
                key={index}
                className="font-semibold text-start break-after-all"
              >
                {val}
              </li>
            ))}
          </ul> */}

          <div>
            <p>{description[0]}</p>
            <br></br>
            {showAll &&
              description.slice(1).map((val, index) => (
                <li key={index} className="text-start break-after-all">
                  <span className="font-semibold">{val.split(":")[0]}</span>
                  <span> :{val.split(":")[1]}</span>
                </li>
              ))}
            {!showAll && description.length > 1 && (
              <button
                onClick={toggleShowAll}
                className="border-[#D1544A] border-[1px] py-2 px-5 rounded-full hover:bg-[#D1544A] hover:text-white text-[#D1544A] font-semibold transition-all mt-5 duration-700 transition-delay-500 ease-in-out"
              >
                Read More
              </button>
            )}
          </div>
        </span>
        <span
          data-aos={!hasScrolledToEnd && `fade-${getRandomAnimation()}`}
          data-aos-offset="10"
          data-aos-easing="ease-in-sine"
          className={`col-span-2 sm:col-span-1  `}
        >
          <img
            className=" transform object-cover w-full my-auto transition-transform duration-200 ease-linear"
            src={imageSrc}
            alt="reload"
          />
        </span>
      </div>
    </div>
  );
};

export default LaravelComponent;
