import React from "react";
import ITResourcesManagementComponent from "../component/ITResourcesManagement";

const ITResourcesManagement = () => {
  return (
    <div>
      <ITResourcesManagementComponent />
    </div>
  );
};

export default ITResourcesManagement;
