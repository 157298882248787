import digitalMarketing from "../../assets/gif/digital_marketing.gif";
import seo from "../../assets/gif/digital_marketing_seo.gif";
import humanCentered from "../../assets/gif/digital_marketing_human_centered.gif";
import contentOptimization from "../../assets/gif/digital_marketing_content_optimization.gif";
import customerInsights from "../../assets/gif/digital_marketing_customer_insights.gif";
import agileDevelopment from "../../assets/gif/digital_marketing_agile_development.gif";
import powerSocialMedia from "../../assets/gif/digital_marketing_power_social_media.gif";
import brandDesign from "../../assets/static/digital_marketing_brand_design.png";
import digitalTransformation from "../../assets/static/digital_marketing_digital_transformation.png";
import marketingCampaigns from "../../assets/static/digital_marketing_marketing_advertising.png";
import userExperience from "../../assets/static/digital_marketing_user_experience.png";
import connectedSales from "../../assets/static/digital_marketing_connected_sales.png";
import brandPublishing from "../../assets/static/digital_marketing_brand_publishing.png";

export const data = {
  development: {
    btncolor: "#35A4DD",
    heading: "DIGITAL MARKETING",
    imageSrc: digitalMarketing,
    buttondirection: "center",
    description:
      "At TACTECH, we specialize in delivering professional digital marketing services to give your brand the online exposure it deserves. We firmly believe that a robust virtual presence is pivotal for success, even for brick-and-mortar businesses. Through our dedicated services, we ensure your brand reaches the right audience, at the right time and on the right platform. Our proven digital strategies have revolutionized client-customer interactions. We're committed to doing the same for you.",
  },
  services: {
    heading: "WE TAKE AN AGILE AND COLLECTIVE APPROACH TO LEVERAGE GROWTH",
    cols: 4,
    bgcolor: "#35A4DD",
    title:
      "We create lifetime memorable experiences between your brand and the customers, to grow your business virtually with our best online marketing services.",
    servicesData: [
      {
        imageUrl: humanCentered,
        title: "USER-CENTRICISM",
        description:
          "TACTECH excels in crafting user-centric designs that prioritize seamless experiences and intuitive interfaces. By understanding user behavior and preferences, we create solutions that resonate. This approach ensures our designs not only captivate but also deliver tangible value to users, driving client success in the digital landscape.",
      },
      {
        imageUrl: contentOptimization,
        title: "TO THE POINT",
        description:
          "TACTECH employs a 'To The Point' strategy in digital marketing, focusing on concise, impactful messaging that resonates with target audiences. By streamlining content and campaigns, we ensure every interaction delivers maximum value, driving engagement, and ultimately, boosting client success in the online sphere.",
      },
      {
        imageUrl: customerInsights,
        title: "KNOWING YOUR AUDIENCE",
        description:
          "TACTECH drives digital marketing through profound customer insights. By analyzing behavior and preferences, we tailor strategies for precise targeting and engagement. This data-driven approach ensures every campaign resonates, maximizing client success in the dynamic digital landscape.",
      },
    ],
  },
  services1: {
    heading: "HOW WE HELP",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: brandDesign,
        title: "BRAND DEVELOPMENT",
        description:
          "At TACTECH, brand development is a fusion of strategic vision and creative innovation. We delve deep into understanding your brand's essence, crafting a unique identity that resonates. Our approach marries data-driven insights with creative flair, ensuring your brand stands out in the dynamic digital landscape.",
      },
      {
        imageUrl: digitalTransformation,
        title: "DIGITAL EVOLUTION",
        description:
          "We digitally revolutionizes brands through strategic innovation. We blend insightful market analysis with creative expertise to craft brand identities that resonate. Our dynamic approach ensures brands not only adapt but thrive in the fast-paced digital landscape, driving lasting connections and business success.",
      },
      {
        imageUrl: marketingCampaigns,
        title: "TARGETED CAMPAIGNS",
        description:
          "At TACTECH, we excel in precision-targeted campaigns, ensuring optimal efficiency. Our approach involves detailed audience analysis, strategic content creation, and data-driven insights. By honing in on specific demographics and interests, we maximize engagement, driving a higher ROI for our clients.",
      },
      {
        imageUrl: userExperience,
        title: "USER-CENTRIC DESIGNS",
        description:
          "We excel in crafting user-centric designs, prioritizing seamless experiences for enhanced retention. We analyze user behavior and preferences, tailoring interfaces that resonate. This approach ensures our designs not only captivate but also deliver tangible value, fostering lasting connections.",
      },
      {
        imageUrl: connectedSales,
        title: "SALES WITH MARKETING",
        description:
          "We understand the pertinence of knowing the market. By aligning sales and marketing, we create a unified customer journey. This holistic approach fosters stronger leads, higher conversions, and ultimately, greater revenue growth for businesses, maximizing their potential in the market.",
      },
      {
        imageUrl: brandPublishing,
        title: "BRAND IDENTITY",
        description:
          "TACTECH crafts robust brand identities by combining strategic vision with creative innovation. We delve deep into understanding a brand's essence, creating distinctive visual and conceptual elements. This fusion of data-driven insights ensures brands resonate effectively with their target audience.",
      },
    ],
  },
  incubationfunding: {
    title: "STRATEGIC SEARCH OPTIMIZATION (SEO)",
    imageSrc: seo,
    description:
      "TACTECH offers extensive SEO services, essential for elevating a website's visibility on search engines. Our approach includes keyword research, on-page optimization, content strategy, and technical enhancements. This boosts rankings, drives organic traffic, and enhances online presence. Trust TACTECH to optimize your performance, ultimately leading to increased conversions and business growth.",
  },
  incubationfunding1: {
    order: "md:order-2",
    descriptionorder: "md:order-1",
    title: "UNLEASH THE SOCIAL POTENTIAL",
    imageSrc: powerSocialMedia,
    description: "Maximizing your services' marketing potential in today's digital landscape demands a strategic focus on social platforms. They offer direct engagement and value delivery to consumers. Partnering with TACTECH, expert digital marketing services, ensures access to a wealth of expertise. We specialize in forging genuine, enduring relationships with industry leaders and consumers, prioritizing meaningful connections over mere online presence. Our strategies, encompassing targeted content and data-driven analytics, are dedicated to amplifying your brand's digital impact."
  },
};
