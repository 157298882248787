import Intro from "./Intro";
import Business from "./Business";
// import Blog from "./Blog";
import Header from "../Header";
import Forms from "../Forms";
import Footer from "../Footer";
import TrustedBy from "../TrustedBy";
import { useRef } from "react";
import Hero from "../Hero";
import Projects from "./Projects";

// import Footer from "../component/Footer";

const HomeComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="text-center overflow-hidden">
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Intro />
      {/* <TrustedBy /> */}
      <Projects />
      <Business />
      {/* <Blog /> */}
      <Forms ref={formRef} />

      <Footer />
    </div>
  );
};

export default HomeComponent;
