import React from "react";
import ProjectComponent from "../component/Projects";

const Project = () => {
  return (
    <div>
      <ProjectComponent />
    </div>
  );
};

export default Project;
