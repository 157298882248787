import React from "react";

// import logo from "../../assets/gif/Final gif white background 1 loop.gif";
// import logo1 from "../../assets/gif/Final Mobile view Whiet background Loop once.gif";

const Business = () => {
  // const [scrollPosition, setScrollPosition] = useState(0);

  // const handleScroll = () => {
  //   const currentPosition = window.scrollY;
  //   setScrollPosition(currentPosition);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // let scale = 0 + 0 * 0;
  // if (scrollPosition >= 500 && scrollPosition <= 700) {
  //   scale = 1 + scrollPosition * 0.0001;
  // } else if (scrollPosition >= 700 && scrollPosition <= 1250) {
  //   scale = 1 + scrollPosition * 0.001;
  // } else {
  //   scale = 0 + 500 * 0.001;
  // }
  // const translateX = scrollPosition * 0.01;

  // const imageStyle = {
  //   transform: `translateX(${translateX}px)  scale(${scale})`,
  //   transition: "transform 200ms linear",
  // };

  return (
    <div className="overflow-hidden">
      <div className="flex flex-col justify-center gap-16">
        <span className="px-20 py-14 ">
          <h1 className="md:text-[50px] text-3xl font-bold">
            ELEVATING YOUR BUSINESS BEYOND LIMITS
          </h1>
          <h1 className="mt-10">
            Curious about the experience of journeying with us? Take a look.
          </h1>
        </span>
        <div className="px-10 lg:px-20">
          <div className="relative w-full h-0 pb-[56.25%]">
            <iframe
              title="TACTECH - Customer Journey"
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/wja3SNeyC8g"
            ></iframe>
          </div>

        </div>
        {/* <span className={`justify-center overflow-hidden md:flex hidden `}>
          <img
            // style={imageStyle}
            className="overflow-hidden object-cover h-full sm:w-[90%] w-full"
            src={logo}
            alt="reload"
          />
        </span>
        <span className={`flex justify-center overflow-hidden md:hidden`}>
          <img
            // style={imageStyle}
            className="overflow-hidden object-contain h-full w-full"
            src={logo1}
            alt="reload"
          />
        </span> */}
      </div>
    </div>
  );
};

export default Business;
