import React, { useRef } from "react";
import Header from "../Header";
import Hero from "../Hero";
import { data } from "./contants";
import Breadcrumb from "../Breadcrumb";
import StartupSection from "../StartupSection";
import ScrollToTop from "../ScrollToTop";
import LaravelComponent from "./laravel";
import Forms from "../Forms";
import Footer from "../Footer";
import Services from "../Services";
import DevelopmentProcess from "../DevelopmentProcess";

const LaravelDevelopmentComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="relative">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"Laravel Development"} />
      <StartupSection content={data.development} formRef={formRef} />

      <Services
        heading={data.services.heading}
        title=""
        bgcolor={data.services.bgcolor}
        services={data.services.servicesData}
      />
      <DevelopmentProcess
        heading={data.developmentservices.heading}
        data={data.developmentservices.servicesData}
        bgcolor={data.developmentservices.bgcolor}
      />
      <LaravelComponent content={data.laravel} />
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default LaravelDevelopmentComponent;
