import React from "react";
import DigitalMarketingComponent from "../component/DigitalMarketingComponent";

const DigitalMarketing = () => {
  return (
    <div>
      <DigitalMarketingComponent />
    </div>
  );
};

export default DigitalMarketing;
