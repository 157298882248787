import webDevelopmentTablet from "../../assets/gif/web_development_tablet.gif";
import webDevelopmentComputer from "../../assets/gif/web_development_computer.gif";
import webDevelopmentMobile from "../../assets/gif/web_development_mobile.gif";

export const data = {
  developmentservices: {
    animated: "down",
    bgColor: "#14446d",
    imageUrl: webDevelopmentMobile,
    title: "STUNNING ON YOUR LAPTOP, EQUALLY CAPTIVATING ON YOUR MOBILE",
    description:
      "Embark on a journey of mobile sophistication with TACTECH. Crafting seamless web experiences demands exceptional skills and a blend of top-tier resources. As your all-inclusive web development agency, we curate highly responsive designs that resonate with both users and your vision, sparking an exceptional interaction.",
  },
  ecommercedata: {
    animated: "down",
    bgColor: "#fc6c14",
    imageUrl: webDevelopmentComputer,
    title: "SHAPING E-COMMERCE INTO A GLOBAL STANDARD OF EXCELLENCE",
    description:
      "Welcome to the world of E-commerce redefined. At TACTECH, perfection isn't an option - it's our standard. As connoisseurs of web development, we translate your business needs into customized online experiences. Unveil cutting-edge functionality, seamless user journeys, and a touch of brilliance. Transcend the ordinary with TACTECH.",
  },
  brandsdata: {
    animated: "up",
    bgColor: "#14446d",
    imageUrl: webDevelopmentTablet,
    title: "CRAFTING WEB DESIGNS THAT EMBRACE YOUR BRAND IDENTITY",
    description:
      "We project your vision to the World Wide Web. Our passion lies in sculpting brands that transcend borders, setting new benchmarks in excellence. We don't just create websites; we weave narratives that echo your essence and style, resonating on a global stage.",
  },
};
