import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import { BsArrowRightShort } from "react-icons/bs";
// import { useNavigate } from "react-router-dom";

interface ServiceItemProps {
  imageUrl: string;
  title?: string;
  description?: string;
  color?: string;
  index?: number;
  hasScrolledToEnd?: boolean;
  bgcolor?: string;
  link?: string;
  cols?: number;
}
interface SwiperProp {
  services: ServiceItemProps[];
  heading: string;
  title?: string;
  bgcolor?: string;
}

const Swiper: React.FC<SwiperProp> = ({
  heading,
  title,
  bgcolor,
  services,
}) => {
  // const navigate = useNavigate();
  const settings = {
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 1500,
    centerMode: true,
    centerPadding: "0",
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="sm:px-[10vw] xl:px-52 px-10 py-14">
      <div className="flex justify-center mb-10">
        <h1
          className={`md:text-[40px] text-center text-${
            bgcolor === "#F5F5F5" || bgcolor === "#E0E0E0" ? "black" : "black"
          } text-2xl text-center font-semibold inline-block leading-10 border-b-[3px] border-${
            bgcolor === "#F5F5F5" || bgcolor === "#E0E0E0"
              ? "[#00B1FF]"
              : "black"
          }  pb-5`}
        >
          {heading}
        </h1>
      </div>
      <Slider
        {...settings}
        className="w-full flex justify-center  mt-20 gap-10 "
      >
        {services.map((val, index) => (
          <div
            key={index}
            style={{ backgroundColor: val.bgcolor, margin: "0 10px" }}
            className="my-auto w-full flex justify-center border-transparent border-[5px] focus:outline-none"
          >
            <img
              className="w-full my-auto d object-contain h-[25vh] px-10 py-10"
              src={val.imageUrl}
              alt={`E-Commerce ${index}`}
            />
            <div className="flex flex-col  p-4 border shadow-xl delay-100 animate-fade duration-200  text-left">
              <h1 className="font-semibold mb-3 break-all">{val.title}</h1>
              <small className="text-center my-5 h-[100px]">
                {(val.description ?? "").length < 150
                  ? val.description
                  : (val.description ?? "").slice(0, 150) + "..."}
              </small>
              {/* <button
                className="text-left flex mt-2"
                onClick={() => navigate("/")}
              >
                View Detail{" "}
                <BsArrowRightShort
                  size={"2.5rem"}
                  className="my-auto hover:translate-x-3 duration-100 "
                />
              </button> */}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Swiper;
