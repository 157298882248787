import React from "react";
import Navbar from "../Navbar";
interface HeaderProps {
  color?: string;
  formRef?: React.RefObject<HTMLDivElement | null>;
}

const Header: React.FC<HeaderProps> = ({ color, formRef }) => {

  return (
    <div>
      <Navbar color={color} formRef={formRef} />
    </div>
  );
};

export default Header;
