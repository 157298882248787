import React, { useRef } from "react";
import Breadcrumb from "../Breadcrumb";
import ScrollToTop from "../ScrollToTop";
import Header from "../Header";
import Hero from "../Hero";
import { data } from "./constants";
import StartupSection from "../StartupSection";
import Forms from "../Forms";
import Footer from "../Footer";
import Services from "../Services";

const CloudServicesComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="overflow-hidden">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"Cloud Services"} />
      <StartupSection content={data.development} formRef={formRef} />
      <Services
        heading={data.services1.heading}
        title=""
        bgcolor={data.services1.bgcolor}
        services={data.services1.servicesData}
        negativeMargin={true}
      />
      <StartupSection content={data.incubationfunding} />
      <StartupSection content={data.incubationfunding1} />
      <StartupSection content={data.incubationfunding2} />
      <StartupSection content={data.incubationfunding3} />
      <StartupSection content={data.incubationfunding4} />
      <StartupSection content={data.incubationfunding5} />

      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default CloudServicesComponent;
