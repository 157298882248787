import React, { useState } from "react";
import Modal from "react-modal";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import webDevelopmentIcon from "../../assets/icons/services_icon_web_development.svg";
import appDevelopmentIcon from "../../assets/icons/services_icon_mobile_development.svg";
import softwareDevelopmentIcon from "../../assets/icons/services_icon_software_development.svg";
import startUpIcon from "../../assets/icons/services_icon_start_ups.svg";
import devOpsIcon from "../../assets/icons/services_icon_dev_ops.svg";
import itResourceIcon from "../../assets/icons/services_icon_it_resource_management.svg";
import laravelDevelopmentIcon from "../../assets/icons/services_icon_laravel.svg";
import ecommerceDevelopmentIcon from "../../assets/icons/services_icon_ecommerce.svg";
import cloudManagementIcon from "../../assets/icons/services_icon_cloud_management_hosting.svg";
import businessIntelligenceIcon from "../../assets/icons/services_icon_data_analytics.svg";
import socialMediaMarketingIcon from "../../assets/icons/services_icon_digital_marketing.svg";

const customStyles: Modal.Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000, // Higher zIndex to cover everything
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "90%",
    height: "90%",
    transform: "translate(-50%, -50%)",
    backdropFilter: "blur(5px) !important",
    animation: "slideInFromTop 0.5s ease-out 0.2s forwards",
    background: "rgba(255, 255, 255, 1)",
    // opacity: 1,
    border: "none",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    overflow: "scroll",
  },
};
interface ServicesModalProps {
  formRef?: React.RefObject<HTMLDivElement | null>;
  onDontKnow?: () => void;
}

const ServicesModal: React.FC<ServicesModalProps> = ({
  formRef,
  onDontKnow,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  function openModal() {
    setIsOpen(true);
  }
  const scrollToForm = () => {
    setIsOpen(false);
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
      if (onDontKnow) {
        onDontKnow();
      }
    }
  };
  function closeModal() {
    setIsOpen(false);
  }
  // useEffect(() => {
  //   if (modalIsOpen) {
  //     // Add a class to the body when the modal is open to prevent scrolling
  //     document.body.classList.add("modal-open");
  //   } else {
  //     // Remove the class when the modal is closed
  //     document.body.classList.remove("modal-open");
  //   }
  // }, [modalIsOpen]);
  return (
    <div>
      {" "}
      <div className="App">
        <p
          //   href="/"
          onClick={openModal}
          className="flex gap-2 hover:text-[#FEA315] py-2 cursor-pointer pr-4 pl-3 text-white border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0"
        >
          Services
          {/* <span className="my-auto dropdown-icon">
            <IoMdArrowDropdown />
          </span> */}
        </p>

        <Modal
          isOpen={modalIsOpen}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          // className="Scroll"
          contentLabel="Services Modal"
          ariaHideApp={false}
        >
          <div className="absolute right-5">
            <GrFormClose
              onClick={() => closeModal()}
              className="text-[#198754] cursor-pointer"
              color="#198754"
              size={"2rem"}
            />
          </div>
          <div className="grid m-auto justify-center grid-cols-2 App lg:grid-cols-5 md:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5">
            {/* Web Development */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/web-design-development")}
                className="w-[40%] md:[w-50%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={webDevelopmentIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                Web Design & Development
              </h1>
            </div>
            {/* App Development */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/mobile-app-development")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={appDevelopmentIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                Mobile App Development
              </h1>
            </div>
            {/* Software Development */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/software-development")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={softwareDevelopmentIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                Software Development
              </h1>
            </div>
            {/* Startup */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/startups")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={startUpIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                Start Ups
              </h1>
            </div>
            {/* DevOps */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/devops")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={devOpsIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                DevOps
              </h1>
            </div>
            {/* It Resource Management */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/it-resource-management")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={itResourceIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                IT Resource Management
              </h1>
            </div>
            {/* Laravel Devlopment */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/laravel-development")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={laravelDevelopmentIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                Laravel Development
              </h1>
            </div>
            {/* Ecommerce Devlopment */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/ecommerce-development")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={ecommerceDevelopmentIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                E-Commerce Development
              </h1>
            </div>
            {/* Cloud Management  */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/cloud-management-hosting")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={cloudManagementIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                Cloud Management & Hosting
              </h1>
            </div>
            {/* Bussiness Intelligence  */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() =>
                  navigate("/data-analytics-business-intelligence")
                }
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%]  transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={businessIntelligenceIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                Data Analytics & Business Intelligence
              </h1>
            </div>
            {/* Social Media Marketing   */}
            <div className="flex flex-col justify-center items-center py-5">
              <img
                onClick={() => navigate("/digital-marketing")}
                className="w-[40%] md:[w-50%] lg:w-[60%] md:w-[60%] xl:w-[60%] 2xl:w-[60%] transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                src={socialMediaMarketingIcon}
                alt=""
              />
              <h1 className="text-[12px] md:text-[16px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  text-center font-semibold">
                Digital Marketing
              </h1>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => {
                scrollToForm();
              }}
              className="App bg-[#198754] px-7 py-2 my-5 text-white  border-[1px] border-[#198754]  rounded-md font-semibold hover:shadow-xl  transition-colors duration-700 transition-delay-500 ease-in-out"
            >
              I Don't Know Yet
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ServicesModal;
