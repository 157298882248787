import React, { useEffect, useState } from "react";
// import Button from "./Button";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
interface ServiceItemProps {
  imageUrl: string;
  title?: string;
  description?: string;
  color?: string;
  index?: number;
  hasScrolledToEnd?: boolean;
  bgcolor?: string;
  link?: string;
  cols?: number;
  navigate?: (link: string) => void; 
  negativeMargin?: boolean
}

const ServiceItem: React.FC<ServiceItemProps> = ({
  imageUrl,
  title,
  description,
  color,
  index,
  hasScrolledToEnd,
  bgcolor,
  cols,
  link,
  navigate,
  negativeMargin = false
}) => {
  return (
    <div>

      <div
        style={{
          backgroundColor: bgcolor,
          color: bgcolor === ("black" || "#4A4A4A") ? "white" : color,
        }}
        className={`flex flex-col bg-${bgcolor} justify-center  hover:scale-105 transition-transform duration-300 p-5 rounded text-${
          bgcolor === "black" ? "white" : color
        }`}
        data-aos={
          !hasScrolledToEnd &&
          index !== undefined &&
          (index === 0 || index === (cols === 3 ? 3 : 5)
            ? `fade-right`
            : index === (cols === 3 ? 2 : 3) || index === 5
            ? `fade-left`
            : "")
        }
        data-aos-duration="1000"
        data-aos-easing="ease-in-sine"
      >
        <div className={`flex justify-center h-[200px]  ${negativeMargin === true ? '-mb-10' : 'mb-10'}`}>
          <img src={imageUrl} className="object-contain" alt="" />
        </div>
        
        <div className="flex gap-2 justify-center">
          <h1 className={`text-2xl my-auto text-center font-semibold`}>{title}</h1>
        </div>
        <p className="mt-5 text-[14px] text-center leading-7">{description}</p>
      </div>
      {link && (
        <div className="flex justify-center mt-5">
          <Button
            text="Learn More"
            color="#FFFFFF"
            bgColor="#14446d"
            borderColor="#14446d"
            hoverBgColor="#FFFFFF"
            hoverColor="#14446d"
            onClick={() => navigate && link && navigate(link)}
          />
        </div>
      )}
    </div>
  );
};

interface ServicesProps {
  heading: string;
  bgcolor: string;
  title?: string;
  services: ServiceItemProps[];
  cols?: number;
  negativeMargin?: boolean
}

const Services: React.FC<ServicesProps> = ({
  heading,
  services,
  bgcolor,
  title,
  cols = 3,
  negativeMargin = false,
}) => {
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      if (
        !hasScrolledToEnd &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 1500
      ) {
        AOS.refresh();
        setHasScrolledToEnd(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledToEnd]);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div
      className={`sm:px-[10vw] xl:px-52 px-10 ${title !== null ? `py-14` : `pb-14`} bg-[${bgcolor}] max-h-full overflow-x-hidden`}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <div className="text-center">
        {heading !== "" && <h1
          className={`md:text-[40px] text-${
            bgcolor === "#F5F5F5" || bgcolor === "#E0E0E0" ? "black" : "white"
          } text-2xl text-center font-semibold inline-block leading-10 border-b-[3px] border-${
            bgcolor === "#F5F5F5" || bgcolor === "#E0E0E0"
              ? `${
                  heading === "LARAVEL MAINTENANCE & SUPPORT"
                    ? "[#D1544A]"
                    : "[#00B1FF]"
                }`
              : "white"
          }  pb-5`}
        >
          {heading}
        </h1>}
        {title != null && <p
          className={`mt-5 text-${
            bgcolor === "#F5F5F5" || bgcolor === "#E0E0E0" ? "black" : "white"
          }`}
        >
          {title}
        </p>}
      </div>
      <div className="flex justify-center mx-auto">
        <div
          className={`grid grid-cols-1 justify-center mx-auto sm:grid-cols-${cols} gap-4 mt-20`}
        >
          {services.map((service, index) => (
            <ServiceItem
              key={index}
              index={index}
              link={service.link}
              navigate={navigate}
              cols={cols}
              hasScrolledToEnd={hasScrolledToEnd}
              color={bgcolor === ("#F5F5F5" || "#FFFFFF") ? "black" : "white"}
              imageUrl={service.imageUrl}
              title={service.title}
              description={service.description}
              bgcolor={service.bgcolor}
              negativeMargin={negativeMargin}
            />
          ))}
        </div>
      </div>
      {/* <div className="flex justify-center mt-10">
        <Button
          text="Get Started"
          color={
            heading === "LARAVEL MAINTENANCE & SUPPORT" ? "#D35D54" : "#FFFFFF"
          }
          bgColor="transparent"
          borderColor={
            heading === "LARAVEL MAINTENANCE & SUPPORT" ? "#D35D54" : "#FFFFFF"
          }
          hoverBgColor={
            heading === "LARAVEL MAINTENANCE & SUPPORT" ? "#D35D54" : "#FFFFFF"
          }
          hoverColor={
            heading === "LARAVEL MAINTENANCE & SUPPORT" ? "#FFFFFF" : "#14446d"
          }
        />
      </div> */}
    </div>
  );
};

export default Services;
