import WebDevelopmentComponent from "../component/WebDevelopment";

const WebDevelopment = () => {
  return (
    <div>
      <WebDevelopmentComponent />
    </div>
  );
};

export default WebDevelopment;
