import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { projects } from "../Projects/constant";
import ProjectModal from "../Projects/ProjectModal";

const Projects = () => {
  const navigate = useNavigate();
  const settings = {
    infinite: true,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
    centerMode: true,
    centerPadding: "0",
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="py-14">
      <div className="px-20">
        <h1 className="md:text-[50px] text-3xl capitalize font-bold">
          WHAT WE'VE BUILT
        </h1>
        <h1 className="mt-10">
          Witness our disruptive software solutions that have deep and long
          lasting impacts on the market, driving the digital presence of
          businesses.
        </h1>
      </div>
      <div className="flex gap-4 justify-center mb-10">
        <Slider
          {...settings}
          className="w-full flex justify-center mt-10 gap-10 px-[20px]"
        >
          {projects.map((val, index) => (
            <div
              key={index}
              className="w-full flex justify-center focus:outline-none px-5"
            >
              <div
                className={`border-[${
                  index % 2 === 0 ? "#0D709E" : "#FEA315"
                }] border-[1px] rounded-lg min-h-[500px] xs:min-h-[300px] md:min-h-[420px] xl:min-h-[500px] flex justify-center`}
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                    borderTopLeftRadius: "0.5rem",
                    borderTopRightRadius: "0.5rem",
                    backgroundColor: val.bgColor,
                  }}
                >
                  <img
                    className="w-full my-auto object-contain"
                    src={val.imgurl}
                    style={{ maxWidth: "200px", minHeight: "200px" }}
                    alt={`${val.title}`}
                  />
                </div>
                <div
                  className={`flex flex-col p-4 hover:bg-[${
                    index % 2 === 0 ? "#0D709E" : "#FEA315"
                  }] delay-100 animate-fade duration-200 hover:text-white text-left`}
                >
                  <h1
                    className="font-semibold mb-3 break-all"
                    style={{ fontSize: "18px" }}
                  >
                    {val.title}
                  </h1>
                  <small style={{ fontSize: "14px" }}>{val.description}</small>
                  <button
                    className="text-left flex mt-2 my-auto flex"
                    style={{ alignItems: "start", paddingTop: "1rem" }}
                  >
                    <ProjectModal project={val} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="pt-10">
        <Button
          text="View All"
          color="#198754"
          bgColor="transparent"
          borderColor="#198754"
          hoverBgColor="#198754"
          hoverColor="#FFFFFF"
          onClick={() => navigate("/projects")}
        />
      </div>
    </div>
  );
};

export default Projects;
