import dataAnalytics from "../../assets/gif/data_analytics.gif";
import smartDecisions from "../../assets/gif/data_analytics_smarter_decisions.gif";
import discoverOpportunities from "../../assets/gif/data_analytics_hidden_opportunities.gif";
import workBetter from "../../assets/gif/data_analytics_work_together.gif";
import readyAction from "../../assets/gif/data_analytics_ready_action.gif";
import visualize from "../../assets/gif/data_analytics_visualize.gif";
import salesAnalytics from "../../assets/static/data_analytics_sales_analytics.png";
import operationsAnalytics from "../../assets/static/data_analytics_operation_analytics.png";
import customerSuccess from "../../assets/static/data_analytics_customer_success.png";
import marketingAnalysis from "../../assets/static/data_analytics_marketing_analysis.png";
import webAnalytics from "../../assets/static/data_analytics_web_analytics.png";
import supportAnalysis from "../../assets/static/data_analytics_support_analysis.png";

export const data = {
  development: {
    btncolor: "#F21860",
    heading: "DATA ANALYTICS & BUSINESS INTELLIGENCE",
    imageSrc: dataAnalytics,
    buttondirection: "center",
    description:
      "TACTECH is revolutionizing the landscape of data analytics and business intelligence, propelling it into a new era of insight-driven decision-making. With cutting-edge technologies and a team of skilled experts, we empower users to extract actionable intelligence from their data, uncovering hidden patterns and trends. Our solutions not only streamline the process of data collection and analysis but also provide intuitive visualizations that make complex information easily digestible. By harnessing the power of advanced analytics, TACTECH equips businesses with the foresight they need to make informed, strategic moves, ultimately driving growth and success in today's data-driven world.",
  },
  services: {
    heading: "",
    cols: 4,
    bgcolor: "#F5F5F5",
    title:
      "",
    servicesData: [
      {
        imageUrl: smartDecisions,
        title: "WELL-INFORMED AND QUICK",
        description:
          "TACTECH employs data analytics and business intelligence for you to make informed, agile decisions. Through advanced technology, we distill complex data into actionable insights, enabling swift responses to dynamic markets. This empowers you to seize opportunities and navigate risks efficiently.",
      },
      {
        imageUrl: discoverOpportunities,
        title: "REVEALING UNTAPPED POTENTIAL",
        description:
          "Data is our friend and we use it to uncover hidden potential. Our in-depth analysis reveals opportunities for operational optimization, improved customer experiences, and robust growth strategies, empowering your business to excel.",
      },
      {
        imageUrl: readyAction,
        title: "DATA DRIVEN AUTOMATION",
        description: "We leverage data analytics and business intelligence to drive businesses towards full automation. Through strategic insights, we streamline operations, minimizing manual tasks and maximizing efficiency. This empowers businesses to operate seamlessly and with unparalleled productivity.",
      },
      {
        imageUrl: workBetter,
        title: "UNITE & CONQUER",
        description:
          "TACTECH maximizes client data with our expertise to drive business growth. Your insights become a strategic asset, fueling informed decisions and innovation. Together, we propel your business to new heights.",
      },
    ],
  },
  services1: {
    heading: "HOW WE HELP",
    bgcolor: "#F5F5F5",
    servicesData: [
      {
        imageUrl: salesAnalytics,
        title: "SALES ANALYTICS",
        description:
          "We revolutionize sales analytics, offering essential tools and expertise for insightful decision-making. Through precise data analysis, we decode customer behavior, market trends, and sales performance. This intelligence refines strategies, targets campaigns, and drives revenue growth for enduring success in a competitive market.",
      },
      {
        imageUrl: operationsAnalytics,
        title: "OPERATIONS ANALYTICS",
        description:
          "For swift inventory and process management, customized tools are crucial. Our Operations Analytics, part of Business Intelligence Services, offer a concise overview and real-time analysis of your collected data, providing a streamlined approach to enhancing efficiency and workflow.",
      },
      {
        imageUrl: customerSuccess,
        title: "CUSTOMER ANALYTICS",
        description:
          "TACTECH revolutionizes customer behavior analytics, providing vital insights to drive strategic decisions. Our advanced tools dissect data, revealing patterns and preferences. This intelligence guides personalized strategies, enhancing customer experiences and fueling business growth.",
      },
      {
        imageUrl: marketingAnalysis,
        title: "MARKETING ANALYTICS",
        description:
          "Crucial for expenditure and ROI evaluation, marketing analytics drives campaigns. Utilize our Business Intelligence expertise to apply advanced techniques, turning data into actionable insights for ROI-driven marketing strategies. Elevate your campaigns with data-driven decisions, maximizing returns on investments.",
      },
      {
        imageUrl: supportAnalysis,
        title: "SUPPORT",
        description:
          "TACTECH delivers top-tier technical support in data analysis and business intelligence. Our team leverages advanced tools to extract actionable insights, enabling informed decision-making. Tailored solutions drive efficiency, innovation, and sustainable growth. With TACTECH, your data becomes a powerful strategic asset.",
      },
      {
        imageUrl: webAnalytics,
        title: "WEB & MOBILE ANALYTICS",
        description:
          "TACTECH empowers businesses with comprehensive web and mobile analytics. Our advanced tools dissect user behavior, preferences, and app performance. This intelligence guides UX/UI enhancements and tailored marketing strategies, ensuring seamless digital experiences and maximizing app effectiveness.",
      },
    ],
  },
  incubationfunding: {
    order: "md:order-2",
    title: "DATA VISUALIZATION",
    imageSrc: visualize,
    description:
      "Data visualization is the cornerstone of informed decision-making. By translating complex data sets into clear, intuitive visuals, it empowers users to discern patterns, trends, and outliers with ease. This visual clarity brings a new level of understanding, enabling stakeholders to identify opportunities, pinpoint challenges, and devise effective strategies. In essence, data visualization transcends raw information, providing a dynamic tool for making swift, well-informed decisions that drive success and innovation in today's data-driven world.",
  },
};
