import { useEffect, useState } from "react";
import logo from "../../assets/gif/business_process.gif";

const Intro = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolledOnImage, setIsScrolledOnImage] = useState(false);

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
    const imageElement = document.getElementById("imageElement");
    if (imageElement) {
      const imageRect = imageElement.getBoundingClientRect();
      if (imageRect.top <= window.innerHeight && imageRect.bottom >= 0) {
        setIsScrolledOnImage(true);
      } else {
        setIsScrolledOnImage(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const maxRotation = 360; // Full rotation in degrees
  // const rotationSpeed = Math.min(1, Math.abs(scrollPosition) * 0.005); // Adjust the scroll speed
  // const maxScale = 1.2; // Maximum scaling value
  // const scaleSpeed = 0.002; // Adjust the scale speed

  // let rotation, scale;

  // if (isScrolledOnImage) {
  //   rotation = Math.min(maxRotation, rotationSpeed * maxRotation);
  //   scale = Math.min(maxScale, 1 + scrollPosition * scaleSpeed);
  // } else {
  //   rotation = Math.max(0, maxRotation - rotationSpeed * maxRotation);
  //   scale = Math.max(0.3, 1 - Math.abs(scrollPosition) * scaleSpeed); // Adjust scale speed
  // }

  // const translateX = isScrolledOnImage ? 0 : scrollPosition * 0.1;
  // const imageStyle = {
  //   transform: `translateX(${translateX}px) rotate(${rotation}deg) scale(${scale})`,
  //   transition: "transform 400ms linear",
  // };
  return (
    <div className="">
      <div className="grid justify-center grid-cols-2 gap-5">
        <span className="col-span-2 order-2 md:col-span-1 flex flex-col justify-center items-center px-20 pb-14">
          <h1 className="md:text-[50px] text-3xl font-bold">
            JOIN US IN SHAPING THE FUTURE
          </h1>
          <h1 className="mt-10">
            We aren't just a software house - we're a mindset, a culture, and a
            commitment to pushing the boundaries of innovation.
            <br />
            <br />
            Whether you're a disruptor or a dreamer, a startup or an industry
            leader, we invite you to join hands with us and create a digital
            future that's as limitless as your imagination.
          </h1>
        </span>
        <span className="col-span-2 order-1 md:col-span-1 flex justify-center px-20 py-14 sm:px-10">
          <img
            id="imageElement"
            // style={imageStyle}
            // data-scroll-step="44"
            className="h-full transform transition-transform duration-200 ease-linear et_pb_image_0 w-full"
            src={logo}
            alt="reload"
          />
        </span>
      </div>
    </div>
  );
};

export default Intro;
