import Breadcrumb from "../Breadcrumb";
import React, { useRef } from "react";
import Footer from "../Footer";
import Forms from "../Forms";
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";
import Brand from "./Brand";
import Services from "./Services";
import { data } from "./constant";
import StartupSection from "../StartupSection";
import Hero from "../Hero";

const AppDevelopmentComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);
  console.log(formRef);
  return (
    <div className="overflow-hidden">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"Mobile App Development"} />
      <Services data={data.servicesData} formRef={formRef} />
      <Brand data={data.progressivewebapp} />
      <Brand data={data.brands} />
      {/* <CustomMobileDevelopment data={data.custommobieDevelopment} /> */}
      <StartupSection content={data.journey} />
      {/* <SimpleMantra /> */}
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default AppDevelopmentComponent;
