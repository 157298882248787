import Header from "../Header";
import React, { useRef } from "react";
import Breadcrumb from "../Breadcrumb";
import Hero from "../Hero";
import StartupSection from "../StartupSection";
import Offer from "../Offer";
import { data } from "./constants";
import Forms from "../Forms";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";
import DevelopmentProcess from "../DevelopmentProcess";

const StartupComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="relative">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"Start Ups"} />
      <StartupSection content={data.startup} formRef={formRef} />
      <Offer heading={data.Offer.heading} services={data.Offer.servicesData} />
      {/* <Services
        heading={data.services.heading}
        bgcolor={data.services.bgcolor}
        services={data.services.servicesData}
      /> */}
      <DevelopmentProcess
        heading={data.services.heading}
        data={data.services.servicesData}
        bgcolor={data.services.bgcolor}
      />

      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default StartupComponent;
