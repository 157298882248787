import React from "react";
import EcommerceComponent from "../component/Ecommerce";

const EcommerceDevelopment = () => {
  return (
    <div>
      <EcommerceComponent />
    </div>
  );
};

export default EcommerceDevelopment;
