import SoftwareDevelopmentComponent from "../component/SoftwareDevelopment";

const SoftwareDevelopment = () => {
  return (
    <div>
      <SoftwareDevelopmentComponent />
    </div>
  );
};

export default SoftwareDevelopment;
