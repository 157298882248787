import React, { useRef } from "react";
import Breadcrumb from "../Breadcrumb";
import ScrollToTop from "../ScrollToTop";
import Header from "../Header";
import Hero from "../Hero";
import { data } from "./constants";
import StartupSection from "../StartupSection";
import Forms from "../Forms";
import Footer from "../Footer";
import Services from "../Services";
import DevelopmentProcess from "../DevelopmentProcess";

const ITResourcesManagementComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"IT Resource Management"} />
      <StartupSection content={data.development} formRef={formRef} />
      <DevelopmentProcess data={data.services.servicesData} />
      <StartupSection content={data.resourcePlanning} />
      {/* <Services
        heading={data.services1.heading}
        title={data.services1.title}
        bgcolor={data.services1.bgcolor}
        services={data.services1.servicesData}
      /> */}
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default ITResourcesManagementComponent;
