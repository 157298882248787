export const navbarcontent = [
  "Home",
  "About",
  "Services",
  "Blog",
  "News",
  "Contact",
  "Careers",
];
export const Technologies = [
  {
    title: "Front End Development",
    description:
      "Our skillful engineers create interactive designs and prototype to ensure a project with a pleasant user experience",
    icon: ["React", "Angular", "Vue"],
  },
  {
    title: "Back End Development",
    description:
      "Our skilled full-stack developers are experts in MEAN & MERN stack. You can hire our development teams separately",
    icon: ["Javascript", "Net", "Java", "laravel", "Python"],
  },
  {
    title: "UI/UX Design",
    description:
      "Our professional UI/UX designers create meaningful interfaces that enable users to feel comfortable while using the platform.",
    icon: ["Illustrator", "Figma", "Invision", "Photoshop"],
  },
  {
    title: "Cloud/Dev Ops",
    description:
      "Our engineers find the ideal balance in operations and development to ensure your product cycles are quicker than the rest.",
    icon: ["AWS", "GoogleCloud", "MicrosoftAzure", "Docker", "kubernets"],
  },
  {
    title: "Database",
    description:
      "Database programmers and developers usually work as part of a software development team. Employ data governance to ensure high-quality data.",
    icon: ["MongoDB", "SqlDatabase", "Neo4j"],
  },
  {
    title: "Others",
    description:
      "We also excel in many other multiple platforms like React Native, Ionic, Arduino, iOS, etc.",
    icon: ["React", "Ionic", "Arduino", "IOS"],
  },
];
export const iconUrls = {
  React: "https://devflovv.com/wp-content/uploads/svg/react-js.svg",
  Angular: "https://devflovv.com/wp-content/uploads/svg/angular-icon-1.svg",
  Vue: "https://devflovv.com/wp-content/uploads/svg/vue-js-1.svg",
  Javascript: "https://devflovv.com/wp-content/uploads/svg/nodejs-icon.svg",
  Net: "https://devflovv.com/wp-content/uploads/svg/dotnet.svg",
  Java: "https://devflovv.com/wp-content/uploads/svg/java-4.svg",
  laravel: "https://devflovv.com/wp-content/uploads/svg/laravel-2.svg",
  Python: "https://devflovv.com/wp-content/uploads/svg/python-5.svg",
  Illustrator: "https://devflovv.com/wp-content/uploads/svg/Illustrator.svg",
  Figma: "https://devflovv.com/wp-content/uploads/svg/Figma.svg",
  Invision: "https://devflovv.com/wp-content/uploads/svg/Invision.svg",
  Photoshop: "https://devflovv.com/wp-content/uploads/svg/Photoshop.svg",
  AWS: "https://devflovv.com/wp-content/uploads/svg/aws-logo.svg",
  GoogleCloud: "https://devflovv.com/wp-content/uploads/svg/google-cloud-1.svg",
  MicrosoftAzure:
    "https://devflovv.com/wp-content/uploads/svg/microsoft-azure-3.svg",
  Docker: "https://devflovv.com/wp-content/uploads/svg/docker.svg",
  kubernets: "https://devflovv.com/wp-content/uploads/svg/kubernets.svg",
  MongoDB: "https://devflovv.com/wp-content/uploads/svg/MongoDB.svg",
  SqlDatabase:
    "https://devflovv.com/wp-content/uploads/svg/sql-database-generic.svg",
  Neo4j: "https://devflovv.com/wp-content/uploads/svg/neo4j.svg",
  Ionic:
    "https://devflovv.com/wp-content/uploads/svg/ionic-icon-svgrepo-com.svg",
  Arduino: "https://devflovv.com/wp-content/uploads/svg/arduino-1.svg",
  IOS: "https://devflovv.com/wp-content/uploads/svg/ios-2.svg",
};
export const projecttypes = [
  "Web Design & Development",
  "Mobile App Development",
  "Software Development",
  "Start Up",
  "DevOps",
  "IT Resource Management",
  "Laravel Development",
  "E-Commerce Development",
  "Cloud Management & Hosting",
  "Data Analytics & Business Intelligence",
  "Digital Marketing",
  "Other",
];
export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
