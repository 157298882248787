import React, { useState, useEffect } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import Modal from "react-modal";
import { countries, projecttypes } from "../constants";
// import { GrFormClose } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Parse from "parse";

const customStyles: Modal.Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    animation: "slideInFromTop 0.5s ease-out 0.2s forwards",
    backgroundColor: "#0D709E",
    opacity: 1,
    border: "none",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    overflow: "auto",
  },
};

const Quotes: React.FC = () => {
  let subtitle: HTMLHeadingElement | null;
  const [modalIsOpen, setIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [project, setProject] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    if (subtitle) {
      subtitle.style.color = "#2E96C7";
    }
  }

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    if (modalIsOpen) {
      // Add a class to the body when the modal is open to prevent scrolling
      document.body.classList.add("modal-open");
    } else {
      // Remove the class when the modal is closed
      document.body.classList.remove("modal-open");
    }
  }, [modalIsOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const Quotation = Parse.Object.extend("Quotation");
      const quotation = new Quotation();

      quotation.set("name", name);
      quotation.set("email", email);
      quotation.set("phone", phone);
      quotation.set("project", project);
      quotation.set("country", country);
      quotation.set("message", message);

      const savedQuotation = await quotation.save(null, { useMasterKey: true });
      if (savedQuotation) {
        toast.success(
          "Submission received successfully. We will get back to you shortly!",
          {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setName("");
        setEmail("");
        setPhone("");
        setProject("");
        setCountry("");
        setMessage("");
      } else {
        toast.error(
          "Submission unsuccessful. Please try again, and if the problem persists, please reach out to us at mohd2000omer@gmail.com",
          {
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
      closeModal();
    } catch (e) {
      toast.error(
        "Submission unsuccessful. Please try again, and if the problem persists, please reach out to us at mohd2000omer@gmail.com",
        {
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      closeModal();
    }
  };

  return (
    <div className="App">
      <button
        onClick={openModal}
        type="button"
        className="bg-[#198754] text-white w-[100%] border-[1px] border-[#198754] px-3 whitespace-nowrap md:px-10 py-2 rounded-full hover:bg-transparent hover:text-[#198754] transition-colors duration-700 transition-delay-500 ease-in-out sm:w-[100%]"
        data-bs-toggle="modal"
        data-bs-target="#quoteModal"
      >
        Get a Free Quote
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Quote Modal"
        ariaHideApp={false}
      >
        <div className="flex justify-end text-white absolute right-5">
          <button
            className="text-white focus:outline-none"
            onClick={() => closeModal()}
          >
            <AiOutlineClose size={"1.5rem"} />
          </button>
        </div>
        <div className="flex flex-col  App ">
          <h1 className="text-center text-lg sm:text-[30px] font-semibold text-white py-5">
            Ready to turn your idea into reality?
          </h1>
          <p className="text-center text-white font-semibold pb-5">
            Get a free quote and consultation for your project.
          </p>
        </div>
        <form
          action=""
          className="md:px-[30%] xs:px-[5.7rem] mt-5 App"
          onSubmit={handleSubmit}
        >
          <small>
            <input
              type="text"
              required
              value={name}
              className="bg-transparent quotesform border-white rounded-lg border-[2px] py-2 mb-3 px-5 w-full  text-white focus:outline-none "
              placeholder="YOUR NAME *"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <input
              type="email"
              value={email}
              required={phone === ""}
              placeholder="YOUR EMAIL *"
              className="bg-transparent quotesform border-white rounded-lg border-[2px] py-2 mb-3 px-5 w-full  text-white focus:outline-none "
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <p className="text-center text-white font-semibold pb-3 text-[12px]">
              AND / OR
            </p>
            <input
              type="tel"
              value={phone}
              required={email === ""}
              placeholder="YOUR PHONE (WITH COUNTRY CODE) *"
              className="bg-transparent quotesform border-white rounded-lg border-[2px] py-2 mb-3 px-5 w-full text-white focus:outline-none "
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <select
              name=""
              id=""
              required
              value={project}
              className="bg-transparent quotesform border-white rounded-lg border-[2px] py-2 mb-3 px-5 w-full text-white focus:outline-none appearance-none"
              onChange={(e) => {
                setProject(e.target.value);
              }}
            >
              <option value="" disabled className="text-black">
                SELECT PROJECT TYPE *
              </option>
              {projecttypes.map((val, idx) => (
                <option key={idx} className="text-black" value={val}>
                  {val}
                </option>
              ))}
            </select>
            <select
              name=""
              id=""
              value={country}
              className="bg-transparent quotesform border-white rounded-lg border-[2px] py-2 mb-3 px-5 w-full  text-white focus:outline-none appearance-none"
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <option value="" disabled className="text-black">
                SELECT COUNTRY
              </option>
              {countries.map((val, idx) => (
                <option key={idx} className="text-black" value={val}>
                  {val}
                </option>
              ))}
            </select>

            <textarea
              name=""
              id=""
              required
              value={message}
              placeholder="YOUR MESSAGE *"
              className="bg-transparent quotesform border-white rounded-lg border-[2px] py-2 px-5 w-full  text-white focus:outline-none "
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></textarea>
            {/* <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || 'DUMMY_SITE_KEY'}/> */}
            <div className="flex justify-center py-5">
              <button className="bg-transparent text-[#FEA315] mt-5  border-[2px] font-semibold border-[#FEA315] px-10 py-2 rounded-full hover:bg-[#FEA315] hover:text-white  transition-colors duration-700 transition-delay-500 ease-in-out">
                Submit
              </button>
            </div>
          </small>
        </form>
      </Modal>
    </div>
  );
};

export default Quotes;
