import React, { useRef } from "react";
import Header from "../Header";
import Breadcrumb from "../Breadcrumb";
// import Services from "../Services";
// import HowWeWork from "./How_we_work";
import Forms from "../Forms";
import Footer from "../Footer";
import Hero from "../Hero";
import StartupSection from "../StartupSection";
import { data } from "./constants";
import ScrollToTop from "../ScrollToTop";
import DevelopmentProcess from "../DevelopmentProcess";
import Offer from "../Offer";

const SoftwareDevelopmentComponent = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="relative">
      <ScrollToTop />
      <div className="relative">
        <Header formRef={formRef} />
        <Hero />
      </div>
      <Breadcrumb title={"Software  Development"} />
      <StartupSection content={data.development} formRef={formRef} />
      {/* <Services
        heading={data.services.heading}
        bgcolor={data.services.bgcolor}
        services={data.services.servicesData}
      /> */}
      <Offer
        heading={data.services.heading}
        services={data.services.servicesData}
      />
      <DevelopmentProcess
        heading={data.howwework.heading}
        data={data.howwework.howWeWorkItems}
        bgcolor={data.services.bgcolor}
      />
      {/* <Delete /> */}
      {/* <HowWeWork
        heading={data.howwework.heading}
        items={data.howwework.howWeWorkItems}
      /> */}
      {/* <Technology /> */}
      <Forms ref={formRef} />
      <Footer />
    </div>
  );
};

export default SoftwareDevelopmentComponent;
